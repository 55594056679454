import IProductVariantDTO from 'productTemplate/types/IProductVariantDTO'
import { ISize } from 'shared/types/ISize'
import { ISizePickerOption } from 'shared/types/ISizePickerOption'
import { BoxCondition } from './types/BoxCondition'

export interface ISizePickerVariantOption {
  size: ISize
  new?: IProductVariantDTO
  used?: IProductVariantDTO
}

export const isBadBox = (boxCondition?: BoxCondition) => {
  return boxCondition !== 'good_condition'
}

export const isConditional = (selectedSize?: ISizePickerOption) => {
  if (!selectedSize || !selectedSize.new) {
    return false
  }

  return (
    selectedSize.new?.shoeCondition === 'new_with_defects' ||
    isBadBox(selectedSize.new?.boxCondition)
  )
}

export const getLowestPrice = (variant?: IProductVariantDTO, fallback = Infinity) => {
  if (variant && variant.lowestPriceCents && variant.lowestPriceCents.amount) {
    return variant.lowestPriceCents.amount
  }
  return fallback
}
export const getInstantShipPrice = (variant?: IProductVariantDTO, fallback = Infinity) => {
  if (
    variant &&
    variant.instantShipLowestPriceCents &&
    variant.instantShipLowestPriceCents.amount
  ) {
    return variant.instantShipLowestPriceCents.amount
  }
  return fallback
}
export const getMarketPrice = (variant?: IProductVariantDTO, fallback = Infinity) =>
  Math.min(getLowestPrice(variant, fallback), getInstantShipPrice(variant, fallback))

const getHighestMarketPrice = (variant?: IProductVariantDTO, fallback = Infinity) =>
  Math.max(getLowestPrice(variant, fallback), getInstantShipPrice(variant, fallback))

export const getLowestPricedVariant = (
  variant1?: IProductVariantDTO,
  variant2?: IProductVariantDTO,
) => (getInstantShipPrice(variant2) < getInstantShipPrice(variant1) ? variant2 : variant1)

export const getLowestMarketPricedVariant = (
  variant1?: IProductVariantDTO,
  variant2?: IProductVariantDTO,
) => (getMarketPrice(variant2) < getMarketPrice(variant1) ? variant2 : variant1)

export const getHighestPricedVariant = (
  variant1?: IProductVariantDTO,
  variant2?: IProductVariantDTO,
) => (getInstantShipPrice(variant1, 0) > getInstantShipPrice(variant2, 0) ? variant1 : variant2)

export const getHighestMarketPricedVariant = (
  variant1?: IProductVariantDTO,
  variant2?: IProductVariantDTO,
) => (getHighestMarketPrice(variant1, 0) > getHighestMarketPrice(variant2, 0) ? variant1 : variant2)

export const createPriceFromVariant = (
  variant?: IProductVariantDTO,
  marketPricingEnabled?: boolean,
) => {
  if (!variant) {
    return null
  }
  if (!marketPricingEnabled && !variant.instantShipLowestPriceCents.amount) {
    return null
  }
  if (!marketPricingEnabled) {
    return {
      value: variant.instantShipLowestPriceCents.amountUsdCents,
      localizedValue: variant.instantShipLowestPriceCents.amount,
    }
  }
  return getInstantShipPrice(variant) < getLowestPrice(variant)
    ? {
        value: variant.instantShipLowestPriceCents.amountUsdCents,
        localizedValue: variant.instantShipLowestPriceCents.amount,
      }
    : {
        value: variant.lowestPriceCents.amountUsdCents,
        localizedValue: variant.lowestPriceCents.amount,
      }
}

const convertProductVariantPricing = (
  variant: IProductVariantDTO,
  marketPricingEnabled: boolean,
) => ({
  size: {
    display: variant.sizeOption.presentation,
    value: variant.sizeOption.value,
  },
  shoeCondition: variant.shoeCondition,
  boxCondition: variant.boxCondition,
  lowestPriceCents:
    marketPricingEnabled && variant.lowestPriceCents.amount
      ? {
          value: variant.lowestPriceCents.amountUsdCents,
          localizedValue: variant.lowestPriceCents.amount,
        }
      : null,
  instantShipLowestPriceCents: variant.instantShipLowestPriceCents.amount && {
    value: variant.instantShipLowestPriceCents.amountUsdCents,
    localizedValue: variant.instantShipLowestPriceCents.amount,
  },
})

export const convertSizePickerVariantPricing = (
  option: ISizePickerVariantOption,
  marketPricingEnabled: boolean,
) => ({
  size: option.size,
  new: option.new && convertProductVariantPricing(option.new, marketPricingEnabled),
  used: option.used && convertProductVariantPricing(option.used, marketPricingEnabled),
})
