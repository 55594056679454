import { FC } from 'react'
import { startCase } from 'lodash/fp'
import styled from 'styled-components'

import { Breadcrumb } from 'shared/types/Breadcrumb'
import BreadcrumbLink from 'shared/components/BreadcrumbLink'
import { breadcrumbSpecialNameMap } from 'head/utils/seoHelpers'
import { colors, fonts, media, styles } from 'shared/lib'
import { FavoriteButton } from 'favorites/FavoriteButton'
import { IProductTaxonomy } from 'shared/types/IProductTaxonomy'
import { ProductQA } from 'shared/dataAttributes'

interface IProductBreadcrumbsProps {
  breadcrumbPath?: Breadcrumb[] | null
  name: string
  productTaxonomy?: IProductTaxonomy[]
  productTemplateId?: string
  productTemplateSlug?: string
  handleFavoriteClick?: () => void
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const ProductBreadcrumbsWrapper = styled.nav`
  padding: 0 ${styles.mobilePageLayoutPadding} 20px;
  ${media.large`
    padding: 0;
    margin: 0 20px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    overflow-wrap: break-word;
    max-width: 600px;
  `}
`
const CrumbWrapper = styled.li`
  display: inline-block;
`
const CrumbList = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 1.6;
`
const ShoeNameCrumb = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${colors.FC2_GREY};
  ${media.large`
    ${fonts.LINK}
    color: ${colors.FC2_GREY};
    font-weight: normal;
    text-decoration: none;
  `};
`

const ProductBreadcrumbs: FC<IProductBreadcrumbsProps> = ({
  breadcrumbPath,
  name,
  productTaxonomy,
  handleFavoriteClick,
  productTemplateId,
  productTemplateSlug,
}) => {
  const shouldShowInnerBreadcrumbs = !!breadcrumbPath && !!breadcrumbPath.length
  return (
    <Wrapper>
      <ProductBreadcrumbsWrapper data-qa={ProductQA.BreadCrumbLinkFullPath}>
        <CrumbList>
          <BreadcrumbLink
            dataQa={ProductQA.BreadcrumbFlightClub}
            href="/"
            fontColor={colors.FC2_GREY}
            hasTrailingSlash
            name="Flight Club"
          />
          {shouldShowInnerBreadcrumbs &&
            breadcrumbPath.map((breadcrumb, i) => {
              const breadCrumbHasNameAndSlug = breadcrumb.name && breadcrumb.slug
              if (breadCrumbHasNameAndSlug) {
                const slug = productTaxonomy?.[i]?.urlOverride || breadcrumb.slug
                return (
                  <BreadcrumbLink
                    key={slug}
                    dataQa={ProductQA.BreadcrumbPathName}
                    href={`/${slug}`}
                    hasTrailingSlash
                    fontColor={colors.FC2_GREY}
                    name={breadcrumbSpecialNameMap.get(breadcrumb.name) || breadcrumb.name}
                  />
                )
              }
            })}
          <CrumbWrapper data-testid="breadcrumbShoeName" data-qa={ProductQA.BreadcrumbShoeName}>
            <ShoeNameCrumb>{startCase(name)}</ShoeNameCrumb>
          </CrumbWrapper>
        </CrumbList>
      </ProductBreadcrumbsWrapper>
      {handleFavoriteClick && productTemplateId && productTemplateSlug && (
        <FavoriteButton
          onClick={handleFavoriteClick}
          productTemplateId={productTemplateId}
          productTemplateSlug={productTemplateSlug}
        />
      )}
    </Wrapper>
  )
}

ProductBreadcrumbs.displayName = 'ProductBreadcrumbs'
export default ProductBreadcrumbs
