import { FC } from 'react'
import { sizes } from 'shared/lib/media'

interface IShrinkWrappedJordan1BredProps {
  className?: string
}

const desktopImgUrl = '/static/shrinkWrappedJordan1Bred/desktop/51181-08-jpg'
const mobileImgUrl = '/static/shrinkWrappedJordan1Bred/mobile/jordan1'

export const ShrinkWrappedJordan1Bred: FC<IShrinkWrappedJordan1BredProps> = ({ className }) => {
  return (
    <picture className={className}>
      <source
        media={`(min-width: ${sizes.large}px)`}
        srcSet={`${desktopImgUrl}.png, ${desktopImgUrl}@2x.png 2x, ${desktopImgUrl}@3x.png 3x`}
      />
      <source
        media={`(max-width: ${sizes.large - 1}px)`}
        srcSet={`${mobileImgUrl}.png, ${mobileImgUrl}@2x.png 2x, ${mobileImgUrl}@3x.png 3x`}
      />
      <img src={`${desktopImgUrl}.png`} alt="Shrink wrapped Jordan 1 Bred" />
    </picture>
  )
}
export default ShrinkWrappedJordan1Bred
