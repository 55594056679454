import styled from 'styled-components'
import { useState, FC, useRef, useEffect } from 'react'
import colors from 'shared/lib/colors'
import zIndex from 'shared/lib/zIndex'
import media from 'shared/lib/media'
import fonts from 'shared/lib/fonts'
import { getAllMenSizes, getAllWomenSizes } from 'shared/lib/sizes'
import SizeTable from './SizeTable'
import { ProductQA } from 'shared/dataAttributes'
import { SVGIcon } from 'shared/components/Icons'
import { FCLink, LinkCTA } from 'shared/components/Links'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { t } from 'localization'
import { Trans } from 'react-i18next'
import { useTranslation } from 'next-i18next'

const TP = 'productTemplate.components.SizeGuide'

const SizeGuideContainer = styled.div`
  line-height: 50px;
  text-align: center;
  ${media.large`
    line-height: 18px;
  `}
`

const GuideModal = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.65);
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.modal};
  overflow-y: auto;
`

const ModalContent = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 90px);
  max-width: 95%;
  top: 80px;
  position: absolute;
  background: ${colors.FC2_WHITE};
  left: 50%;
  transform: translateX(-50%);
  padding: 16px;
  width: 640px;
  ${media.large`
    top: 130px;
  `}
`

const Title = styled.h2`
  ${fonts.HEADER_1}
`

const ModalHeader = styled.div``
const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  border-top: 1px solid ${colors.FC2_LIGHTEST_GREY};
  margin-top: 25px;
`

const Description = styled.div`
  text-align: center;
  margin: 13px 0;
  ${fonts.BODY_TEXT}
`

const StyledSizeTable = styled(SizeTable)``

const MensSizeTable = styled(StyledSizeTable)`
  padding: 20px 20px 20px 0;
  flex-grow: 1;
`

const WomensSizeTable = styled(StyledSizeTable)`
  padding: 20px 0 20px 20px;
  flex-grow: 1;
`

const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
`

interface ISizeGuideProps {
  className?: string
}

const menSizes = getAllMenSizes()
const womenSizes = getAllWomenSizes()

export const SizeGuide: FC<ISizeGuideProps> = props => {
  const tv = useTranslation()
  const { className } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const wrapperEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (isModalOpen && wrapperEl.current) {
        disableBodyScroll(wrapperEl.current)
      } else {
        clearAllBodyScrollLocks()
      }
    }, 0)

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isModalOpen, wrapperEl?.current])

  return (
    <>
      <LinkCTA
        onClick={openModal}
        qaAttr={ProductQA.SizeGuideLink}
        dataTestId="sizeGuideLink"
        color="grey"
        className={className}
      >
        {t(`${TP}.sizeGuide`, 'Size Guide')}
      </LinkCTA>
      <SizeGuideContainer className={className}>
        <GuideModal
          ref={wrapperEl}
          hidden={!isModalOpen}
          onClick={closeModal}
          data-qa={ProductQA.SizeGuideClose}
        >
          <ModalContent onClick={event => event.stopPropagation()}>
            <CloseButton onClick={closeModal}>
              <SVGIcon name="closeX" color={colors.FC2_BLACK} />
            </CloseButton>
            <ModalHeader />
            <Title data-qa={ProductQA.SizeGuideTitle}>
              {t(`${TP}.shoeSizeGuide`, 'Shoe Size Guide')}
            </Title>
            <Description data-qa={ProductQA.SizeGuideDescription}>
              <Trans
                i18nKey={`${TP}.SizeGuideDescription`}
                t={tv.t}
                defaults="Need help with sizing? We are here to help you 7 days a week: 12pm - 7pm EST<0></0><1>support@flightclub.com</1>"
                components={[
                  <br />,
                  <FCLink
                    qaAttr={ProductQA.SizeGuideSupportEmail}
                    href="mailto:support@flightclub.com"
                  />,
                ]}
              />
            </Description>
            <ModalBody>
              <MensSizeTable title="men" sizes={menSizes} />
              <WomensSizeTable title="womens" sizes={womenSizes} />
            </ModalBody>
          </ModalContent>
        </GuideModal>
      </SizeGuideContainer>
    </>
  )
}
SizeGuide.displayName = 'SizeGuide'

export default SizeGuide
