import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

import { useGetBuyBarCopyQuery } from 'api/productVariantsApi'
import RadioButton from 'checkout/components/RadioButton'
import RadioGroup from 'checkout/components/RadioGroup'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { t } from 'localization'
import Badge from 'productTemplate/components/Badge'
import { InstantShipCallOut } from 'productTemplate/components/InstantShipCallOut'
import ModalPanelText from 'productTemplate/components/ModalPanelText'
import { useProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import { LinkCTA } from 'shared/components/Links'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { ProductQA } from 'shared/dataAttributes'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { colors, fonts } from 'shared/lib'
import IPrice from 'shared/types/IPrice'

const TP = 'productTemplate.components.ProductPicker.InstantShipToggle'
type InstantShipToggleProps = {
  newLowestPrice?: IPrice
  newInstantShipPrice?: IPrice
  openPricingModal?: () => void
}

export const InstantShipToggle = ({
  newLowestPrice,
  newInstantShipPrice,
  openPricingModal,
}: InstantShipToggleProps) => {
  const isMarketPricing = useFeatureFlag(FeatureFlag.MARKET_PRICING)
  const isStandardHolidayShipping = useFeatureFlag(
    FeatureFlag.TEMP_WEB_FC_HOLIDAY_SHIPPING_2022_STANDARD,
  )
  const hideFastestToYouButton = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_PDP_HIDE_FASTEST_TO_YOU)
  const {
    selectedConditionalProduct,
    selectedInstantShip,
    setInstantShip,
    setSelectedConditionalProduct,
    isApparel,
    productTemplate,
  } = useProductTemplateContext()
  const newNoConditionsProductSelected = !selectedConditionalProduct
  const { countryName, country } = useShoppingRegionContext()
  const { slug } = productTemplate
  const { data } = useGetBuyBarCopyQuery({ slug, country })
  const isJapan = countryName === 'Japan'

  const handleSelectInstantShip = (isInstantShip: boolean) => {
    setInstantShip(isInstantShip)
    setSelectedConditionalProduct()
  }
  const showLowestPrice = () => {
    if (hideFastestToYouButton) {
      return newLowestPrice && !isApparel
    }
    return newLowestPrice && !isApparel && newLowestPrice?.display !== newInstantShipPrice?.display
  }

  // remove this when we pull isMarketPricing
  if (!isMarketPricing && !newInstantShipPrice) {
    return null
  }

  if (!newLowestPrice && !newInstantShipPrice) {
    return null
  }

  return (
    <>
      {isMarketPricing && (
        <>
          <RadioGroup name="instantShipToggle">
            {showLowestPrice() && (
              <RadioButton
                value="lowestPrice"
                title={
                  hideFastestToYouButton ? (
                    <>
                      <Price data-qa={ProductQA.ShipBuyNewLowestPriceAmount} priceAligned>
                        {newLowestPrice?.display}
                      </Price>
                      <Badge
                        qaAttr={ProductQA.ShipBuyNewLowestPriceTag}
                        text={t(`${TP}.lowestPrice`, 'Lowest Price')}
                        priceAligned
                      />
                    </>
                  ) : (
                    <Price>{newLowestPrice?.display}</Price>
                  )
                }
                tags={
                  !hideFastestToYouButton && (
                    <Badge
                      qaAttr={ProductQA.ShipBuyNewLowestPriceTag}
                      text={t(`${TP}.lowestPrice`, 'Lowest Price')}
                      rightAligned
                    />
                  )
                }
                subtitle={
                  <ShippingDaysText>
                    <ReactMarkdown>
                      {data?.buyBarLowestPriceCopy || 'Ships to us first for verification'}
                    </ReactMarkdown>
                    {newInstantShipPrice && hideFastestToYouButton && (
                      <>
                        <StyledLinkCTA
                          qaAttr={ProductQA.HideF2YLearnAboutPricingLink}
                          dataTestId="pricingInfoLink"
                          color="inherit"
                          onClick={openPricingModal}
                        >
                          {t(`${TP}.fastestToYouPricingInfoLink`, 'Fastest To You')}
                        </StyledLinkCTA>
                        <span data-qa={ProductQA.HideF2YLearnAboutPricingCopy}>
                          &nbsp;also available at checkout
                        </span>
                      </>
                    )}
                  </ShippingDaysText>
                }
                checked={newNoConditionsProductSelected && !selectedInstantShip}
                onRadioSelect={() => handleSelectInstantShip(false)}
                data-testid="lowestPriceOption"
                qaAttr={ProductQA.BuyNewLowestPriceOption}
              />
            )}
            {newInstantShipPrice && isApparel && hideFastestToYouButton ? (
              <RadioButton
                value="instantShip"
                title={<Price>{newInstantShipPrice.display}</Price>}
                subtitle={<InstantShipCallOut />}
                checked={newNoConditionsProductSelected && selectedInstantShip}
                onRadioSelect={() => handleSelectInstantShip(true)}
                data-testid="instantShipOption"
                qaAttr={ProductQA.BuyNewInstantShipOption}
              />
            ) : (
              newInstantShipPrice &&
              !hideFastestToYouButton && (
                <RadioButton
                  value="instantShip"
                  title={<Price>{newInstantShipPrice.display}</Price>}
                  tags={
                    <Badge
                      qaAttr={ProductQA.ShipBuyNewInstantShipTag}
                      text={t(`${TP}.instantShip`, 'Fastest to you')}
                      badgeType="primary2"
                      rightAligned
                    />
                  }
                  subtitle={<InstantShipCallOut />}
                  checked={newNoConditionsProductSelected && selectedInstantShip}
                  onRadioSelect={() => handleSelectInstantShip(true)}
                  data-testid="instantShipOption"
                  qaAttr={ProductQA.BuyNewInstantShipOption}
                />
              )
            )}
          </RadioGroup>
          {!isJapan && newInstantShipPrice && !isStandardHolidayShipping && (
            <ModalPanelText data-qa={ProductQA.BuyNewShippingNote}>
              <ReactMarkdown>{data?.buyBarDisclaimerCopy}</ReactMarkdown>
            </ModalPanelText>
          )}
        </>
      )}
      {!isMarketPricing && newInstantShipPrice && (
        <SingleRadioButton
          value="instantShip"
          title={<Price>{newInstantShipPrice.display}</Price>}
          subtitle={
            <Subtitle>
              {t(`${TP}.buyNewInstantShipOption`, 'Pre-verified and ready to ship')}
            </Subtitle>
          }
          checked={newNoConditionsProductSelected && selectedInstantShip}
          onRadioSelect={() => handleSelectInstantShip(true)}
          data-testid="singleOption"
          qaAttr={ProductQA.BuyNewInstantShipOption}
        />
      )}
    </>
  )
}

const ShippingDaysText = styled.span`
  ${fonts.SUBTITLE_3}
  color: ${colors.FC2_GREY};
`

const Price = styled.p<{ priceAligned?: boolean }>`
  margin: 0;
  ${fonts.SUBTITLE_1}
  ${({ priceAligned }) => (priceAligned ? 'display: inline-block' : '')}
`

const Subtitle = styled.p`
  ${fonts.SUBTITLE_3}
  color: ${colors.FC2_GREY};
  margin: 0;
`

const SingleRadioButton = styled(RadioButton)`
  margin-bottom: 0;
`

const StyledLinkCTA = styled(LinkCTA)`
  font-size: 12px;
  color: ${colors.FC2_RED};
  text-decoration: underline;
`
