import Bugsnag, { NotifiableError } from '@bugsnag/js'
import styled from 'styled-components'

import CheckMarkWhite from 'shared/svg/check_mark_white.svg'
import { colors, fonts, media } from 'shared/lib'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { t } from 'localization'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { useFetchUserFavoritesForPTQuery } from 'api/productTemplateListsApi'
import { useUser } from 'shared/hooks/useUser'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { sizes } from 'shared/lib/media'
import { useSaveUserFavoritesMutation } from 'api/productTemplateListsApi'

type FavoriteButtonProps = {
  onClick: () => void
  productTemplateId: string
  productTemplateSlug: string
}

export const FavoriteButton = ({
  onClick,
  productTemplateId,
  productTemplateSlug,
}: FavoriteButtonProps) => {
  const TP = 'favorites.components.FavoriteButton'
  const { isAuthenticated, currentUser } = useUser()
  const { data: userFavorites } = useFetchUserFavoritesForPTQuery(
    {
      productTemplateId,
      currentUserId: currentUser?.id,
    },
    { skip: !isAuthenticated },
  )
  const [saveUserFavorites] = useSaveUserFavoritesMutation()

  const isFavoritesEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_FAVORITES)

  const hasFavorites =
    isAuthenticated && userFavorites?.sizes ? userFavorites?.sizes?.length > 0 : false

  const availableSizes = userFavorites?.productTemplate?.sizes

  const shouldAutoSave =
    availableSizes &&
    availableSizes?.length === 1 &&
    availableSizes[0]?.presentationValue === 'one size'

  const handleAutoSave = async () => {
    const sizes = !hasFavorites ? [availableSizes?.[0]?.size ?? 0] : []

    try {
      await saveUserFavorites({
        productTemplateId,
        sizes,
      }).unwrap()
    } catch (error) {
      Bugsnag.notify(error as NotifiableError)
    }
  }

  const handleClick = async () => {
    sendTrackingEvent('FAVORITE_TAP', {
      product_template_slug: productTemplateSlug,
      is_checked: hasFavorites,
    })
    if (shouldAutoSave) {
      return handleAutoSave()
    }
    onClick()
  }

  if (!isFavoritesEnabled) {
    return null
  }

  return (
    <Wrapper>
      <Button data-qa="FavoriteButton" onClick={handleClick} $hasFavorites={hasFavorites}>
        {t(`${TP}.buttonText`, 'Favorite')}
        {hasFavorites && (
          <CheckMarkWrapper data-qa="CheckMarkWrapper">
            <CheckMarkWhite />
          </CheckMarkWrapper>
        )}
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${media.large`
    padding-right: 48px;
  `}

  @media (max-width: ${sizes.large / 16}em) {
    display: flex;
    margin-top: 20px;
    max-width: 680px;
    justify-content: flex-start;
  }
`

const Button = styled.button<{ $hasFavorites: boolean }>`
  ${({ $hasFavorites }) =>
    $hasFavorites &&
    `
  background-color: ${colors.FC2_BLACK};
  stroke:  ${colors.FC2_WHITE};
  color: ${colors.FC2_WHITE};
`};
  min-width: 74px;
  min-height: 28px;
  ${fonts.STANDARD};
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  gap: 8px;
  border: 1.5px solid ${colors.FC2_BLACK};
  border-radius: 16px;
`
const CheckMarkWrapper = styled.div`
  padding-top: 1px;
`
