import { breadcrumbSpecialNameMap, canonicalSlugMap } from 'head/utils/seoHelpers'
import { startCase } from 'lodash/fp'
import { FC } from 'react'
import BreadcrumbLink from 'shared/components/BreadcrumbLink'
import { SearchQA } from 'shared/dataAttributes'
import { colors, fonts, media, pageWidth, styles } from 'shared/lib'
import { Breadcrumb } from 'shared/types/Breadcrumb'
import styled from 'styled-components'

interface IProductListPageBreadcrumbsProps {
  breadcrumbPath: Breadcrumb[] | null | undefined
}

const ProductBreadcrumbsWrapper = styled.nav`
  padding: 0 ${styles.mobilePageLayoutPadding} 20px;
  width: 100%;
  ${pageWidth}
  ${media.large`
    padding: 0 0 20px;
    margin: 0 auto;
  `}
`
const CrumbList = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
`
const CrumbWrapper = styled.li`
  ${fonts.BODY_TEXT}
  display: inline-block;
`
const LastCrumb = styled.span`
  font-weight: 500;
  padding-left: 5px;
  color: ${colors.FC2_BLACK};
`

const ProductListPageBreadcrumbs: FC<IProductListPageBreadcrumbsProps> = ({ breadcrumbPath }) => {
  const hasBreadcrumbs = !!breadcrumbPath && !!breadcrumbPath.length

  if (!hasBreadcrumbs) {
    return null
  }

  return (
    <ProductBreadcrumbsWrapper
      data-qa={SearchQA.BreadcrumbLinkFullPath}
      data-testid="PlpBreadcrumbs"
    >
      <CrumbList>
        {breadcrumbPath?.map((breadcrumb, i) => {
          const breadCrumbHasNameAndSlug = breadcrumb.name && breadcrumb.slug
          const isLastBreadcrumb = i === breadcrumbPath.length - 1

          if (isLastBreadcrumb) {
            return (
              <CrumbWrapper
                data-testid="breadcrumbSearchName"
                data-qa={SearchQA.BreadcrumbSearchName}
                key={breadcrumb.slug}
              >
                <LastCrumb>{startCase(breadcrumb.name)}</LastCrumb>
              </CrumbWrapper>
            )
          }
          if (breadCrumbHasNameAndSlug) {
            return (
              <BreadcrumbLink
                key={breadcrumb.slug}
                dataQa={SearchQA.BreadcrumbPathName}
                href={canonicalSlugMap.get(breadcrumb.slug) || `/${breadcrumb.slug}`}
                name={breadcrumbSpecialNameMap.get(breadcrumb.name) || breadcrumb.name}
                hasTrailingSlash
              />
            )
          }
        })}
      </CrumbList>
    </ProductBreadcrumbsWrapper>
  )
}

ProductListPageBreadcrumbs.displayName = 'ProductListPageBreadcrumbs'
export default ProductListPageBreadcrumbs
