import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'

/*
Note: When the user clicks BUY NEW from the Favorites Panel, they are then redirected to
the PDP page. This hook enables the product modal to open and proceed to checkout .
*/
export const useOpenProductModal = () => {
  const router = useRouter()
  const { productTemplate, selectedSize, setSize, setActiveProductModal } =
    useProductTemplateContext()
  useEffect(() => {
    const { size, type, slug } = router?.query || {}
    if (slug && size && type && (type === 'favorite' || type === 'offer')) {
      if (selectedSize?.size.display !== size) {
        const sizePickerOption = productTemplate?.sizePickerOptions?.find(
          (shoe) => shoe?.size?.display === size && shoe.new,
        )
        if (sizePickerOption) {
          setSize(sizePickerOption)
        }
      }
      setActiveProductModal('new')
    }
  }, [router?.query?.size, router?.query?.type, router?.query?.slug])

  return undefined
}
