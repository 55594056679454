import { ISizePickerOption } from 'shared/types/ISizePickerOption'
import { ProductQA } from 'shared/dataAttributes'

import BuyButtonRow from './BuyButtonRow'
import { SizeOptionTile, SizeOptionWrapper } from './ProductPickerStyles'
import { useProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'

interface ISizePickerDesktopProps {
  sizePickerOptions: ISizePickerOption[]
}

const SizePickerDesktop = ({ sizePickerOptions }: ISizePickerDesktopProps) => {
  const { setSize, selectedSize } = useProductTemplateContext()
  return (
    <>
      <SizeOptionWrapper data-qa={ProductQA.DesktopSizePicker}>
        {sizePickerOptions.map(option => (
          <SizeOptionTile
            isSelected={option.size.value === selectedSize?.size.value}
            onClick={() => setSize(option)}
            key={option.size.value}
          >
            {option.size.display}
          </SizeOptionTile>
        ))}
      </SizeOptionWrapper>
      <BuyButtonRow />
    </>
  )
}

export default SizePickerDesktop
