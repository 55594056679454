import { CSSProperties, useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { FreeMode, Navigation, Scrollbar, Thumbs } from 'swiper/modules'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/css/thumbs'

import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { ProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import { useMediaQuery } from 'react-responsive'
import Image from 'shared/components/Image'
import { ProductQA } from 'shared/dataAttributes'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import media, { sizes } from 'shared/lib/media'

type GalleryProps = {
  /** navigation type for the carousel */
  navigationType?: 'bullets' | 'none' | 'scrollbar'
  /** array of image urls */
  pictureUrls: string[]
}
const TP = `shared.components.Carousel.Carousel`

/** Carousel component for displaying images in a horizontal slider */
const Carousel = ({
  navigationType = 'scrollbar',
  pictureUrls,
}: GalleryProps) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>()
  const swiperRef = useRef(null)
  const isThumbGalleryEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_THUMB_GALLERY)
  const hasManyPictures = pictureUrls?.length > 1
  const thumbsPerView = Math.min(4, pictureUrls.length)
  const thumbWidth = 48
  const isMobile = useMediaQuery({ maxWidth: sizes.small })
  const {
    productTemplate: { slug },
  } = useContext(ProductTemplateContext)
  const getImages = (urls: string[]) => getSlides(urls, 'hero', slug)
  const getThumbs = (urls: string[]) => getSlides(urls, 'thumbs', slug)

  // Add data-qa attribute to Swiper navigation buttons
  useEffect(() => {
    const swiperEl = (swiperRef.current as unknown) as Element
    const prevButton = swiperEl.querySelector('.swiper-button-prev')
    const nextButton = swiperEl.querySelector('.swiper-button-next')
    prevButton?.setAttribute('data-qa', `${ProductQA.LeftArrowPictureCarousel}`)
    nextButton?.setAttribute('data-qa', `${ProductQA.RightArrowPictureCarousel}`)
  }, [])

  return (
    <Wrapper>
      <Swiper
        ref={swiperRef}
        style={
          {
            '--swiper-navigation-color': '#000',
            '--swiper-navigation-size': '20px',
            '--swiper-pagination-color': '#000',
            backgroundColor: 'white',
          } as CSSProperties
        }
        spaceBetween={10}
        modules={[FreeMode, Navigation, Thumbs, Scrollbar]}
        pagination={
          navigationType === 'bullets' && {
            type: 'bullets',
          }
        }
        navigation={hasManyPictures && !isMobile}
        thumbs={{ swiper: thumbsSwiper }}
        className="gallery-top"
      >
        {getImages(pictureUrls)}
      </Swiper>
      {isThumbGalleryEnabled && pictureUrls.length > 1 && navigationType !== 'bullets' && (
        <Swiper
          spaceBetween={20}
          modules={[FreeMode, Navigation, Scrollbar]}
          onSwiper={s => setThumbsSwiper(s)}
          slidesPerView={thumbsPerView}
          freeMode={true}
          watchSlidesProgress={true}
          scrollbar={{
            hide: true,
            draggable: true,
            snapOnRelease: true,
            dragSize: thumbWidth,
          }}
          className="gallery-thumbs"
        >
          {getThumbs(pictureUrls)}
        </Swiper>
      )}
    </Wrapper>
  )
}

/**
 * Returns an array of SwiperSlide components with images
 * @param images  array of image urls
 * @param type  'thumbs' | 'main'
 * @param slug  product template slug
 */
const getSlides = (
  images: string[],
  type: 'thumbs' | 'hero',
  slug: string,
) => {
  return (
    images &&
    images.map((image, i) => (
      <SwiperSlide
        key={`${type}-${image}-${i}`}
        onClick={() => {
          if (type === 'thumbs') {
            sendTrackingEvent('PDP_THUMBNAIL_IMAGE_TAP', {
              product_template_slug: slug,
              picture_id: image,
            })
          }
        }}
      >
        <ImageContainer type={type}>
          <Image
            data-qa={
              type === 'hero'
                ? ProductQA.MainCarouselProductImage
                : ProductQA.MainCarouselProductThumb
            }
            data-testid={type === 'hero' ? 'carousel-image' : 'carousel-thumb'}
            alt={t(`${TP}.carouselImageAlt`, 'carousel image')}
            src={image}
            sizes={
              type === 'hero'
                ? '(max-width: 940px) 100vw, (max-width: 1200px) 472px, 630px'
                : `(min-width: ${sizes.small}px) 300px, 100vw`
            }
            width={type=== 'hero' ? 1250 : 100}
            height={1140}
          />
        </ImageContainer>
      </SwiperSlide>
    ))
  )
}

const Wrapper = styled.div`
  min-width: 350px;

  .swiper-slide {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .swiper-slide img {
    max-width: 527px;
  }

  /* hero top swiper */
  .gallery-top {
    margin-bottom: 10px;
    ${media.large`
      margin-bottom: 40px;
    `}

    .swiper-slide {
      ${media.large`
        width: 520px !important;
      `}

      ${media.extraLarge`
        width: 630px !important;
     `}
    }
  }

  /* bottom thumbs */
  .gallery-thumbs {
    box-sizing: border-box;
    height: 20%;
    padding: 20px 0;
    width: 252px;
  }

  .gallery-thumbs .swiper-slide {
    height: 100%;
    opacity: 0.4;
    cursor: pointer;
    width: 25%;
  }

  .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
  }
`

const ImageContainer = styled.div`
  height: ${props => (props.type === 'hero' ? '375px' : '34px')};
`

export default Carousel
