import { keys, map } from 'lodash/fp'

export const getActiveFilterTypes = filters =>
  keys(filters).filter(key => key !== '__typename' && filters[key])

const getFilterStringFromFilterList = (filter, values) =>
  map(value => `${filter}:"${value}"`, values).join(' OR ')

const getFilterStringFromNumericFilter = (numericFilter, values) =>
  map(value => `${numericFilter}=${value}`, values).join(' OR ')

export const formatAlgoliaFilters = (filterTypes, filters) =>
  map(
    filterType => getFilterStringFromFilterList(filterType, filters[filterType]),
    filterTypes,
  ).join(' AND ')

export const formatAlgoliaNumericFilters = (numericFilterTypes, numericFilters) =>
  map(
    filterType => getFilterStringFromNumericFilter(filterType, numericFilters[filterType]),
    numericFilterTypes,
  ).join(' AND ')

export const combineFiltersAndNumericFilters = (filterString, numericFilterString) =>
  [filterString, numericFilterString].filter(Boolean).join(' AND ')
