import styled from 'styled-components'

import PDPDivider from 'shared/components/PDPDivider'
import { media } from 'shared/lib'

import ProductDescription from './ProductDescription'
import Spinner from 'shared/components/Spinner'
import { ProductPicker } from './ProductPicker'
import { ShippingAndReturnsDropdown } from './ShippingAndReturnsDropdown'
import { useProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import { t } from 'localization'

const TP = 'productTemplate.components.ProductDetails'

const ProductDetailsBox = styled.div`
  max-width: 430px;
  width: 100%;
  margin: 10px 0;
`
const PositionedDividerMobileOnly = styled(PDPDivider)`
  position: absolute;
  left: 0;
  width: 100vw;
  ${media.large`
    display: none;
  `}
`
const PositionedDivider = styled(PDPDivider)`
  position: absolute;
  left: 0;
  width: 100%;
  ${media.large`
    position: relative;
  `}
`

const ProductDetails = () => {
  const { productTemplate, loadingPricing } = useProductTemplateContext()
  return (
    <ProductDetailsBox>
      <Spinner
        showSpinner={loadingPricing}
        text={t(`${TP}.gettingLatestPrices`, 'Getting latest prices')}
      >
        <ProductPicker />
      </Spinner>
      <PositionedDivider />
      <ProductDescription
        sku={productTemplate.sku}
        description={productTemplate.description}
        releaseDate={productTemplate.releaseDate.shortDisplay}
        storyHtml={productTemplate.storyHtml}
      />
      <PositionedDividerMobileOnly />
      <ShippingAndReturnsDropdown />
    </ProductDetailsBox>
  )
}

export default ProductDetails
