import { gql } from '@apollo/client'
import { pipe, startCase } from 'lodash/fp'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { addBreadCrumbPositions, createListItem, mapToListItems } from 'head/utils/seoHelpers'
import { getLocalePath, t } from 'localization'
import append from 'shared/lib/append'
import prepend from 'shared/lib/prepend'
import { Breadcrumb } from 'shared/types/Breadcrumb'

const TP = 'productTemplate.components.SeoBreadcrumbList'

interface SeoBreadcrumbListProps {
  listData: {
    name: string
    hostname: string
    pathname: string
    breadcrumbPath: Breadcrumb[]
  }
}
const createBreadcrumbList =
  (hostname: string, pathname: string, name: string, locale?: string) => (crumbs: Breadcrumb[]) => {
    const path = (() => {
      if (pathname === '/') {
        return ''
      }
      if (pathname?.includes('?')) {
        return pathname.split('?')[0]
      }
      return pathname
    })()
    return pipe(
      prepend([
        createListItem(
          `${hostname}${locale ? getLocalePath('', locale) : ''}`,
          t(`${TP}.flightClub`, 'Flight Club'),
        ),
      ]),
      append(mapToListItems(hostname, locale)(crumbs)),
      append([createListItem(`${hostname}${getLocalePath(path, locale)}`, startCase(name))]),
      addBreadCrumbPositions,
    )([])
  }

const SeoBreadcrumbList = ({
  listData: { hostname, pathname, name, breadcrumbPath },
}: SeoBreadcrumbListProps) => {
  const router = useRouter()
  const breadcrumbList = createBreadcrumbList(
    hostname,
    pathname,
    name,
    router?.locale,
  )(breadcrumbPath)
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: breadcrumbList,
          }),
        }}
      />
    </Head>
  )
}

SeoBreadcrumbList.fragments = {
  seoBreadcrumbList: gql`
    {
      hostname
      name
      pathname
      breadcrumbPath {
        name
        slug
      }
    }
  `,
}

export default SeoBreadcrumbList
