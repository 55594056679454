import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

import { useGetBuyBarCopyQuery, useGetPriceBreakdownQuery } from 'api/productVariantsApi'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { t } from 'localization'
import Badge from 'productTemplate/components/Badge'
import ModalPanel from 'productTemplate/components/ModalPanel'
import PriceBreakdown from 'productTemplate/components/ProductPicker/PriceBreakdown'
import { useProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import Spinner from 'shared/components/Spinner'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import ProductQA from 'shared/dataAttributes/ProductQA'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { colors, fonts } from 'shared/lib'

const TP = 'productTemplate.components.ProductPicker.panels.FastToYouPanel'

const FastestToYouPanel = () => {
  const hideFastestToYouPricing = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_PDP_HIDE_FASTEST_TO_YOU)
  const { productTemplate, selectedSize, orderInfo } = useProductTemplateContext()
  const { country: selectedCountryCode } = useShoppingRegionContext()
  const priceCents = selectedSize?.new?.instantShipLowestPriceCents?.value || 0
  const boxCondition = selectedSize?.new?.boxCondition || ''
  const shoeCondition = selectedSize?.new?.shoeCondition || ''
  const size = selectedSize?.size.value || ''
  const { slug } = productTemplate
  const { data: pricingModalCopy } = useGetBuyBarCopyQuery({ slug, country: selectedCountryCode })
  const fallbackCopy =
    'Product is pre-verified and ready to ship from a Flight Club facility. Priority Processing includes up to an 8% fee to ensure your order is prioritized for quicker delivery to you.'

  const { data: priceBreakdownData, isFetching } = useGetPriceBreakdownQuery(
    {
      boxCondition,
      countryCode: selectedCountryCode,
      isInstantShip: true,
      priceCents,
      productId: `${orderInfo?.productId || ''}`,
      productTemplateId: productTemplate.id,
      shoeCondition,
      size: `${size}`,
    },
    {
      skip: hideFastestToYouPricing,
      refetchOnMountOrArgChange: true,
    },
  )

  return (
    <ModalPanel hasDivider={false} qaAttr={ProductQA.PopupFastestToYou}>
      <Badge
        qaAttr={ProductQA.PopupFastestToYouBadge}
        text={t(`${TP}.popupFastestToYouBadge`, 'Fastest to you')}
        badgeType="primary2"
        data-testid="fastestToYouBadge"
      />
      <FastestToYouText data-qa={ProductQA.PopupFastestToYouText} data-testid="fastestToYouText">
        <ReactMarkdown>
          {pricingModalCopy?.pricingModalFastestToYouCopy || fallbackCopy}
        </ReactMarkdown>
      </FastestToYouText>
      {isFetching && <Spinner showSpinner />}
      {priceBreakdownData && !hideFastestToYouPricing && !isFetching && (
        <PriceBreakdown
          data-testid="pricingBreakdown"
          basePrice={priceBreakdownData?.localizedBasePriceCents}
          markupFee={priceBreakdownData?.localizedMarkupFeeCents}
          totalPrice={priceBreakdownData?.localizedFinalPriceCents}
        />
      )}
    </ModalPanel>
  )
}

const FastestToYouText = styled.div`
  margin-top: 15px;
  ${fonts.BODY_TEXT};
  color: ${colors.FC2_BLACK};
`

export default FastestToYouPanel
