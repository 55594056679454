import styled from 'styled-components'
import { fonts, colors } from 'shared/lib'
import ProductQA from 'shared/dataAttributes/ProductQA'
import ModalPanel from '../../ModalPanel'
import Badge from '../../Badge'
import { t } from 'localization'
import { useProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { useGetBuyBarCopyQuery } from 'api/productVariantsApi'
import ReactMarkdown from 'react-markdown'

const TP = 'productTemplate.components.ProductPicker.panels.LowestPricePanel'

const LowestPriceText = styled.div`
  margin-top: 15px;
  ${fonts.BODY_TEXT};
  color: ${colors.FC2_BLACK};
`

const LowestPricePanel = () => {
  const { productTemplate } = useProductTemplateContext()
  const { country } = useShoppingRegionContext()
  const { slug } = productTemplate
  const { data } = useGetBuyBarCopyQuery({ slug, country })

  const fallbackCopy =
    'Product will be sent to Flight Club first for verification before shipping to you, unless it has been pre-verified.'
  return (
    <ModalPanel qaAttr={ProductQA.PopupLowestPrice}>
      <Badge
        qaAttr={ProductQA.PopupLowestPriceBadge}
        text={t(`${TP}.lowestPrice`, 'Lowest price')}
        data-testid="lowestPriceBadge"
      />
      <LowestPriceText data-qa={ProductQA.PopupLowestPriceText} data-testid="lowestPriceText">
        <ReactMarkdown>{data?.pricingModalLowestPriceCopy || fallbackCopy}</ReactMarkdown>
      </LowestPriceText>
    </ModalPanel>
  )
}

export default LowestPricePanel
