import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { FC } from 'react'
import { Card } from 'shared/components/Card'
import LinkCTA from 'shared/components/Links/LinkCTA'
import { ProductQA } from 'shared/dataAttributes'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { colors, fonts, media } from 'shared/lib'
import styled from 'styled-components'
import { t } from 'localization'
import { Trans } from 'react-i18next'
import { useTranslation } from 'next-i18next'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'

const TP = 'productTemplate.components.ShippingAndReturnsDropdown'

const Terms = styled.div`
  ${fonts.BODY_TEXT}
  line-height: 18px;
  color: ${colors.FC2_BLACK};
  margin-bottom: 20px;

  ${media.large`
    &:last-child {
      margin-bottom: 30px;
    }
  `}
`

const Title = styled.p`
  ${fonts.SUBTITLE_3}
  margin: 0;
  ${media.large`
    &:first-child {
      margin-top: -10px;
    }
  `}
`

const StyledLink = styled(LinkCTA)`
  ${fonts.LINK}
  line-height: 18px;
`

const StyledCard = styled(Card)`
  border: none;
  ${media.large`
    margin: 30px 0 60px;
    border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
    border-top: 1px solid ${colors.FC2_LIGHTEST_GREY};
  `}
`

export const ShippingAndReturnsDropdown: FC = () => {
  const tv = useTranslation()
  const isMarketPricing = useFeatureFlag(FeatureFlag.MARKET_PRICING)
  const isOrderCancellationEnabled = useFeatureFlag(FeatureFlag.WEB_FC_ORDER_CANCELLATION)
  const { countryName } = useShoppingRegionContext()

  const determineDeliveryTerms = () => {
    if (countryName === 'Japan') {
      return (
        <>
          <p>
            {t(
              `${TP}.shippingAndReturnsTermsJP1`,
              'Delivery and processing speeds vary by pricing options. All delivery times are estimates and are not guaranteed. Shipments may be affected by weather-related delays, carrier limitations or other events outside of our control. ',
            )}
          </p>
          <p>
            {t(
              `${TP}.shippingAndReturnsTermsJP2`,
              '“Fastest to You” orders are pre-verified and ready to ship from a Flight Club facility.',
            )}
          </p>
          <p>
            {t(
              `${TP}.shippingAndReturnsTermsJP3`,
              '"Lowest Price" orders may ship to Flight Club first for verification.',
            )}
          </p>
        </>
      )
    } else {
      return (
        <>
          <p>
            {t(
              `${TP}.shippingAndReturnsTerms1`,
              'Delivery and processing speeds vary by pricing options. The following shipping estimates apply only to the contiguous US and exclude deliveries to PO boxes and military bases. All delivery times are estimates and are not guaranteed. Shipments may be affected by weather-related delays, carrier limitations or other events outside of our control.',
            )}
          </p>
          <p>
            {t(
              `${TP}.shippingAndReturnsTerms2`,
              '"Lowest Price" orders may ship to Flight Club first for verification and typically take 7-10 business days (M-F, excluding holidays) to reach you. It will typically take 3-5 business days (M-F, excluding holidays) for a "Fastest To You" item, ordered before 2 PM ET with standard shipping, to be delivered.',
            )}
          </p>
          <p>
            {t(
              `${TP}.shippingAndReturnsTerms3`,
              'Estimated delivery times do not apply to international orders. International customers are responsible for any additional fees or taxes after an item ships.',
            )}
          </p>
        </>
      )
    }
  }

  return (
    <StyledCard
      data-testid="shippingAndReturnsCard"
      qaAttr={ProductQA.DeliveryAndReturns}
      title={t(`${TP}.shippingReturns`, 'Shipping & Returns')}
    >
      {isOrderCancellationEnabled && (
        <>
          <Title
            data-qa={ProductQA.DeliveryAndReturnsCancellationTitle}
            data-testid="orderCancellationTitle"
          >
            {t(`${TP}.cancelations`, 'Cancelations')}
          </Title>
          <Terms
            data-qa={ProductQA.DeliveryAndReturnsCancellationTerms}
            data-testid="orderCancellationTerms"
          >
            <p>
              {t(
                `${TP}.orderCancellationTerms1`,
                'For sneakers, you may cancel your order within 3 hours of placing it or before it is confirmed by the seller - whichever comes first. If 3 hours have passed from when you placed the order and/or the seller has already confirmed the order, then the order cannot be canceled. If neither have occurred, you can cancel your order first by accessing it via “Track My Order” or “My Account,” clicking “Cancel Order” and following the instructions.',
              )}
            </p>
            <p>
              {t(
                `${TP}.orderCancellationTerms2`,
                'If you checked out as a guest, you will need to create a Flight Club account.',
              )}
            </p>
          </Terms>
        </>
      )}
      <Title
        data-qa={ProductQA.DeliveryAndReturnsDeliveryTitle}
        data-testid="shippingAndReturnsTitle"
      >
        {t(`${TP}.delivery`, 'Delivery')}
      </Title>
      <Terms
        data-qa={ProductQA.DeliveryAndReturnsDeliveryTerms}
        data-testid="shippingAndReturnsTerms"
      >
        {isMarketPricing ? (
          determineDeliveryTerms()
        ) : (
          <>
            <p>
              {t(
                `${TP}.shippingAndReturnsTerms4`,
                'Processing and delivery times may vary based on item and location. Orders can take up to seven business days to process. Expedited shipping available on qualified orders in checkout.',
              )}
            </p>
            <p>
              {t(
                `${TP}.shippingAndReturnsTerms5`,
                'International customers are responsible for any additional fees or taxes after an item ships. Estimated delivery times do not apply to international orders.',
              )}
            </p>
          </>
        )}
      </Terms>
      <Title
        data-qa={ProductQA.DeliveryAndReturnsReturnsTitle}
        data-testid="shippingAndReturnsTitle"
      >
        {t(`${TP}.returns`, 'Returns')}
      </Title>
      <Terms
        data-qa={ProductQA.DeliveryAndReturnsReturnsTerms}
        data-testid="shippingAndReturnsTerms"
      >
        <p>{t(`${TP}.shippingAndReturnsTerms6`, 'All sales with Flight Club are final.')}</p>
        <p>
          <Trans
            i18nKey={`${TP}.shippingAndReturnsTerms7`}
            t={tv.t}
            defaults="If you have any questions or concerns with regard to sizing or condition of a specific product on our site, please <0>contact us</0> before purchasing."
            components={[<a href="https://support.flightclub.com/hc/en-us/requests/new" />]}
          />
        </p>
        <StyledLink href="/shipping">{t(`${TP}.learnMore`, 'Learn more')}</StyledLink>
      </Terms>
    </StyledCard>
  )
}

export default ShippingAndReturnsDropdown
