import styled from 'styled-components'
import { colors, fonts } from 'shared/lib'
import { sizes } from 'shared/lib/media'

import SizeGuide from 'productTemplate/components/SizeGuide'

interface ISizeTileProps {
  isSelected: boolean
}

export const ProductPickerWrapper = styled.div`
  margin-bottom: 30px;
`

export const TopLabelSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
`

export const SizingLabel = styled.label`
  ${fonts.SUBTITLE_2}
  color: ${colors.FC2_BLACK}
`

export const StyledSizeGuide = styled(SizeGuide)`
  flex-basis: 30%;
  min-width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SizeOptionWrapper = styled.div`
  display: grid;
  column-gap: 5px;
  row-gap: 5px;
  grid-template-columns: repeat(5, 1fr);
`

export const SizeOptionTile = styled.button`
  ${fonts.SUBTITLE_2}
  cursor: pointer;
  line-height: 42px;
  border: 1.5px solid
    ${({ isSelected }: ISizeTileProps) =>
      isSelected ? colors.FC2_BLACK : colors.FC2_LIGHTEST_GREY};

  @media (min-width: ${sizes.large / 16}em) {
    &:hover {
      border: 1.5px solid
        ${({ isSelected }: { isSelected: boolean }) =>
          isSelected ? colors.FC2_BLACK : colors.FC2_GREY};
    }
  }
`

export const SizeInputField = styled.button`
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${fonts.SUBTITLE_2};
  color: ${colors.FC2_BLACK};
  border: 1.5px solid ${colors.FC2_BLACK};
  padding: 0 20px;
  margin-bottom: 20px;
`

export const MobileSizePickerModalTitle = styled.h3`
  ${fonts.SUBTITLE_1}
  margin: 0;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const MobileOverlayPanel = styled.div`
  margin: 30px 20px;
`

export const SizeLabel = styled.h4`
  ${fonts.SUBTITLE_2}
`
