import { gql, useQuery } from '@apollo/client'
import { t } from 'localization'
import { getOr } from 'lodash/fp'
import { useEffect } from 'react'
import { Button } from 'shared/components/Button'
import { LinkCTA } from 'shared/components/Links'
import { GridContent } from 'shared/components/ProductGrid/GridContent'
import ProductGrid from 'shared/components/ProductGrid/ProductGrid'
import Spinner from 'shared/components/Spinner'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { ProductQA } from 'shared/dataAttributes'
import { fonts, media, styles } from 'shared/lib'
import styled from 'styled-components'
import TrendingProducts from './TrendingProducts'

const TP = 'productTemplate.components.ProductRecommendations'

const PRICE_DISPLAY_PARTS = gql`
  fragment PriceDisplayParts on Price {
    localizedValue
    display(useGrouping: true, hideCents: true)
  }
`

export const GET_RECOMMENDED_PRODUCTS = gql`
  query getRecommendedProducts($id: ID!) {
    getRecommendedProducts(id: $id) {
      id
      slug
      brandName
      name
      pictureUrl
      price {
        value
        ...PriceDisplayParts
      }
    }
  }
  ${PRICE_DISPLAY_PARTS}
`

type ProductRecommendationsProps = {
  buttonLabel?: string
  productTemplateId: string
  title?: string
}

export const ProductRecommendations = ({
  buttonLabel,
  productTemplateId,
  title = t(`${TP}.recommendedForYou`, 'Recommended For You'),
}: ProductRecommendationsProps) => {
  const { loading, error, data, refetch } = useQuery(GET_RECOMMENDED_PRODUCTS, {
    variables: { id: productTemplateId },
  })

  const { currencyCode: selectedCurrency } = useShoppingRegionContext()

  useEffect(() => {
    refetch()
  }, [selectedCurrency])

  const recommendations = getOr([], 'getRecommendedProducts', data)
  return (
    <Content>
      <Spinner showSpinner={loading}>
        {!loading && (
          <GridContent data-testid="productRecommendations">
            <TitleContainer>
              <Title data-qa={ProductQA.RecommendedForYouTitle}>{title}</Title>
              {!!buttonLabel && (
                <TabletAndDesktopButtonLink
                  qaAttr={ProductQA.DesktopRecommendedShopAllLink}
                  href="/sneakers"
                  color="grey"
                >
                  {buttonLabel}
                </TabletAndDesktopButtonLink>
              )}
            </TitleContainer>
            {recommendations?.length !== 0 && (
              <ProductGrid products={recommendations} hidePrices={true} />
            )}
            {/* if there no recommendations to render, we render trending products instead
              Potential performance enhancement: Trending products is not server side rendered since
              its conditionally rendered based on recommendation availability, this will cause a
              re-render since it will differ from the server side render dom tree. Since this is an
              edge case (ie only when recs isn't working) we'll leave for now
            */}
            {(recommendations.length === 0 || error) && <TrendingProducts hidePrices={true} />}
            {!!buttonLabel && (
              <MobileButtonLink
                data-qa={ProductQA.MobileRecommendedShopAllLink}
                href="/sneakers"
                buttonType="secondary"
                $fill
              >
                {buttonLabel}
              </MobileButtonLink>
            )}
          </GridContent>
        )}
      </Spinner>
    </Content>
  )
}

const Title = styled.h2`
  ${fonts.HEADER_1}
  margin-top: 0;
  margin-bottom: 30px;
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px ${styles.mobilePageLayoutPadding};
`
const TabletAndDesktopButtonLink = styled(LinkCTA)`
  display: none;
  ${media.medium`
    display: block;
  `}
`
const MobileButtonLink = styled(Button)`
  margin-top: 40px;
  display: block;
  ${media.medium`
    display: none;
  `}
`
