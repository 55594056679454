import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { ForwardedRef, forwardRef, useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  useFetchUserFavoritesForPTQuery,
  useSaveUserFavoritesMutation,
} from 'api/productTemplateListsApi'
import { SizePicker } from 'favorites/SizePicker'
import { t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { getSizeLabel } from 'productTemplate/utils/getSizeLabel'
import Button from 'shared/components/Button'
import { CloseX } from 'shared/components/Icons/SVGIcons/CloseX'
import { useUser } from 'shared/hooks/useUser'
import { colors, fonts, media } from 'shared/lib'
import { sizes } from 'shared/lib/media'
import { PTListsSizePickerOption } from 'shared/types/ProductTemplateLists'

type FavoritesModalContentsProps = {
  isApparel: boolean
  sizeCategory: string
  handleClose: () => void
  productTemplateId: string
}

export const FavoritesModalContents = forwardRef(
  (
    { isApparel, sizeCategory, handleClose, productTemplateId }: FavoritesModalContentsProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { isAuthenticated, currentUser } = useUser()
    const { data: userFavorites } = useFetchUserFavoritesForPTQuery(
      {
        productTemplateId,
        currentUserId: currentUser?.id,
      },
      { skip: !isAuthenticated },
    )
    const [saveUserFavorites, { isLoading }] = useSaveUserFavoritesMutation()

    const [selectedSizes, setSizes] = useState<number[]>(userFavorites?.sizes || [])

    const TP = 'favorites.components.FavoritesModalContents'

    const sizeLabel = getSizeLabel({
      isApparel,
      sizeCategory,
      TP: {
        basePath: TP,
        youthLabelPath: 'sizeLabelYouth',
        defaultLabelPath: 'sizeLabel',
        fallbackLabel: `US {sizeCategory} Sizes`,
      },
    })

    const hasFavorites =
      isAuthenticated && userFavorites?.sizes ? userFavorites?.sizes?.length > 0 : false

    const handleSizeSelections = (option: PTListsSizePickerOption) => {
      const sizeAlreadySelected = selectedSizes.find(
        (selectionValue) => option.size === selectionValue,
      )
      if (sizeAlreadySelected) {
        setSizes((oldArr) =>
          oldArr.filter((selectionValue) => {
            return selectionValue !== option.size
          }),
        )
      } else {
        setSizes((oldArr) => [...oldArr, option.size])
      }
    }

    const handleSendTrackingEvent = () => {
      const currentlySelected = new Set(selectedSizes)
      const unselectedSizes =
        userFavorites?.sizes?.reduce((arr, size) => {
          if (!currentlySelected.has(size)) {
            arr.push(size)
          }
          return arr
        }, [] as number[]) || []

      sendTrackingEvent('FAVORITE_SAVE_TAP', {
        product_template_slug: userFavorites?.productTemplate?.slug || '',
        sizes_selected: `[${selectedSizes}]`,
        sizes_unselected: `[${unselectedSizes}]`,
      })
    }

    const handleSave = async () => {
      try {
        handleSendTrackingEvent()
        await saveUserFavorites({ productTemplateId, sizes: selectedSizes })
        handleClose()
      } catch (error) {
        Bugsnag.notify(error as NotifiableError)
      }
    }

    useEffect(() => {
      if (userFavorites?.sizes) setSizes(userFavorites.sizes)
    }, [userFavorites?.sizes])

    useEffect(() => {
      /* [Note]: This covers an edge case for safari when a user
        uses the auto fill option on Mac OS. Sometimes the user will be 
        auto logged in after choosing the option, which causes the page 
        to scroll down to the email sign up at the bottom of the page 

        Slight delay is needed or the scrollTo function wont work
      */
      setTimeout(function () {
        window.scrollTo(0, 0)
      }, 0)
    }, [])

    return (
      <FavoritesModalContentsWrapper ref={ref}>
        <ModalHeader>
          <ModalLabel data-qa="FavoritesModalLabel">
            {t(`${TP}.modalLabel`, 'Save Favorite')}
          </ModalLabel>
          <CloseButton data-qa="FavoritesModalCloseButton" onClick={handleClose}>
            <CloseX />
          </CloseButton>
        </ModalHeader>
        <ModalBodyWrapper>
          <ModalDescription data-qa="FavoritesModalDescription">
            {t(
              `${TP}.modalDescription`,
              'Receive exclusive updates on price changes, restocks and more when you add items to your Favorites.',
            )}
          </ModalDescription>
          <SizingLabel data-qa="FavoritesModalSizeLabel">{sizeLabel}</SizingLabel>
          <SizePicker
            onChange={handleSizeSelections}
            selectedSizes={selectedSizes}
            sizePickerOptions={userFavorites?.productTemplate?.sizes || []}
          />
        </ModalBodyWrapper>
        <ButtonWrapper>
          <Button
            buttonType="primary2"
            disabled={isLoading || (!hasFavorites && selectedSizes?.length === 0)}
            $fill
            onClick={handleSave}
            qaAttr="FavoritesModalSaveButton"
            data-qa="FavoritesModalSaveButton"
          >
            {t(`${TP}.modalSaveButtonText`, 'Save')}
          </Button>
        </ButtonWrapper>
      </FavoritesModalContentsWrapper>
    )
  },
)

FavoritesModalContents.displayName = 'FavoritesModalContents'

const FavoritesModalContentsWrapper = styled.div`
  background-color: ${colors.FC2_WHITE};
  ${media.large`
    padding: 20px;
    max-width: 450px;
    border: 1px solid ${colors.FC2_BLACK}
`}

  @media (max-width: ${sizes.large / 16}em) {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    overscroll-behavior-y: none;
  }
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${sizes.large / 16}em) {
    border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
    padding: 20px 20px 0px 20px;
  }
`

const ModalBodyWrapper = styled.div`
  @media (max-width: ${sizes.large / 16}em) {
    padding: 20px;
    flex: 1;
    overflow-y: scroll;
  }
`

const CloseButton = styled.button`
  width: 15px;
  height: 15px;
`

const ModalLabel = styled.div`
  margin-bottom: 20px;
  text-transform: uppercase;
  ${fonts.SUBTITLE_1}
`

const ModalDescription = styled.div`
  margin-bottom: 40px;
  font-size: 14px;
  color: ${colors.FC2_BLACK};
`

const SizingLabel = styled.div`
  margin-bottom: 20px;
  ${fonts.SUBTITLE_2}
`

const ButtonWrapper = styled.div`
  padding: 40px 0px 0px 0px;
  > button {
    text-transform: uppercase;
  }

  @media (max-width: ${sizes.large / 16}em) {
    padding: 20px;
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
  }
`
