import { FC } from 'react'
import { colors, fonts } from 'shared/lib'
import styled from 'styled-components'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { useGetBuyBarCopyQuery } from 'api/productVariantsApi'
import { useProductTemplateContext } from './ProductTemplateContextProvider'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const InstantShipWrapper = styled.div``

const OrderBy = styled.span`
  ${fonts.SUBTITLE_3}
  color: ${colors.FC2_GREY};
`

interface IInstantShipCallOut {
  qaAttr?: string
}

export const InstantShipCallOut: FC<IInstantShipCallOut> = ({ qaAttr }) => {
  const { country } = useShoppingRegionContext()
  const { productTemplate } = useProductTemplateContext()
  const { slug } = productTemplate
  const { data } = useGetBuyBarCopyQuery({ slug, country })

  return (
    <InstantShipWrapper data-qa={qaAttr}>
      <OrderBy>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {data?.buyBarFastestToYouCopy ||
            'Pre-verified and ready to ship from a Flight Club facility'}
        </ReactMarkdown>
      </OrderBy>
    </InstantShipWrapper>
  )
}
