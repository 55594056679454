import { FC, ReactNode } from 'react'
import { BlurredBackDropSideSheet } from './BlurredBackDrop'
import styled from 'styled-components'
import { zIndex, media, colors } from 'shared/lib'

interface IModalProps {
  isOpen: boolean
  children?: ReactNode
  widthOverride?: string
  hasPadding?: boolean
  noBorder?: boolean
}

const ModalBlurredBackDrop = styled(BlurredBackDropSideSheet)`
  ${media.large`
    z-index: ${zIndex.justOverNav};
  `}
`
const Content = styled.div<{ widthOverride?: string; hasPadding?: boolean, noBorder?: boolean }>`
  z-index: ${zIndex.sideSheetModal};
  ${({ noBorder }) => (noBorder ? '' : `border: 1px solid ${colors.FC2_BLACK};`)}
  background-color: ${colors.FC2_WHITE};
  width: 100%;
  margin: 20px;
  padding: ${({ hasPadding }) => (hasPadding ? '20px' : 0)};
  ${({ widthOverride }) =>
    media.large`
      width: ${widthOverride ? widthOverride : '445px'};
    `}
`

export const SideSheetModal: FC<IModalProps> = ({
  isOpen,
  children,
  widthOverride,
  hasPadding = true,
  noBorder,
}) => {
  return isOpen ? (
    <ModalBlurredBackDrop data-dismiss-on-click isActive={isOpen}>
      <Content widthOverride={widthOverride} hasPadding={hasPadding} noBorder={noBorder}>
        {children}
      </Content>
    </ModalBlurredBackDrop>
  ) : null
}

export default SideSheetModal
