import { NextPageContext } from 'next'
import Head from 'next/head'
import { SearchResults } from 'react-instantsearch-core'
import { findResultsState } from 'react-instantsearch-dom/server'
import { AlgoliaIndex } from 'search'
import styled from 'styled-components'

import Bugsnag from '@bugsnag/js'
import ShrinkWrappedJordan1Bred from 'notFound/ShrinkWrappedJordan1Bred'
import AlgoliaSearchClient from 'search/components/AlgoliaSearchClient'
import PageLevelInstantSearch from 'search/components/PageLevelInstantSearch'
import TrendingProducts from 'search/components/TrendingProducts'
import { ErrorPagesQA } from 'shared/dataAttributes'
import { colors, fonts, media, styles } from 'shared/lib'

interface IErrorStatusProps {
  header: string
  subtitle: string
  title: string
}

const statusInfo = new Map<number, IErrorStatusProps>([
  [
    404,
    {
      title: '404 Not Found',
      header: '404 - Page Not Found',
      subtitle:
        'Sorry, we could not find this page. Please search again or navigate to the home page to find what you are looking for.',
    },
  ],
  [
    500,
    {
      title: '500 Internal Server Error',
      header: 'Something went wrong.',
      subtitle:
        "An error has occurred and we're working to fix it. Please try again in a few minutes",
    },
  ],
])

interface IErrorProps {
  statusCode?: number
  trendingResultsState?: SearchResults
}

const Error = ({ statusCode, trendingResultsState }: IErrorProps) => {
  const errorInfo = (statusCode && statusInfo.get(statusCode)) || statusInfo.get(404)
  const { header, subtitle, title } = errorInfo
  return (
    <>
      <Head>
        <title>{title} | Flight Club</title>
      </Head>
      <TopBanner>
        <BannerMessage>
          <Header data-qa={ErrorPagesQA.ErrorPageSectionTitle}>{header}</Header>
          <Subtitle data-qa={ErrorPagesQA.ErrorPageSectionSubTitle}>{subtitle}</Subtitle>
        </BannerMessage>
        <SneakerImage />
      </TopBanner>
      <TrendingProducts resultsState={trendingResultsState!} />
    </>
  )
}

Error.getInitialProps = async (context: NextPageContext) => {
  const { res, err } = context

  if (err) {
    // Must be created within getInitialProps because this relies on runtime configs.  If invoked
    // outside of getInitialProps or componentDidMount, the project will try to create a bugsnag
    // client when building
    Bugsnag.notify(err)
  }

  const trendingResultsState = await findResultsState(PageLevelInstantSearch, {
    indexName: AlgoliaIndex.Trending,
    searchClient: AlgoliaSearchClient,
  })

  const statusCode = res ? res.statusCode : err ? err.statusCode : 404

  return { statusCode, trendingResultsState }
}

const TopBanner = styled.div`
  background-color: ${colors.FC2_WHITE};
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
  padding: ${styles.mobilePageLayoutPadding};
`

const BannerMessage = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0 40px;
`

const Header = styled.h1`
  ${fonts.HEADER_1};
  font-weight: 500;
`

const Subtitle = styled.h2`
  ${fonts.BODY_TEXT};
  font-weight: unset;
`

const SneakerImage = styled(ShrinkWrappedJordan1Bred)`
  ${media.large`
    margin: 50px 20px;
  `}
`

export default Error
