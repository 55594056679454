import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { get, isNil, omit, omitBy, pipe } from 'lodash/fp'
import qs from 'qs'
import { SearchState } from 'react-instantsearch-core'
import { AlgoliaIndex } from 'search'

import Bugsnag from '@bugsnag/js'
import { AxiosError } from 'axios'
import {
  FilteredGridPage,
  FilteredGridPageProps,
  GET_BREADCRUMB_INFO,
  GetBreadcrumbInfoData,
  GetBreadcrumbInfoVars,
} from 'grid/FilteredGridPage'
import { getDefaultLocalePath, serverSideConfigs } from 'localization'
import { ProductPageProps, ProductTemplatePage } from 'productTemplate/ProductTemplatePage'
import { GET_PRODUCT_TEMPLATE } from 'productTemplate/queries'
import { logger } from 'server/utils/logger'
import createApolloClient from 'shared/apollo/createApolloClient'
import { DEFAULT_COUNTRY_CODE, DEFAULT_CURRENCY_CODE } from 'shared/enums/SitePreferences'
import { IProductTemplate } from 'shared/types/IProductTemplate'
import { getResultsState, urlToSearchState } from 'shared/utils/algolia-utils'
import { FCGetServerSidePropsContext, getServerPageProps } from 'shared/utils/getServerSideProps'
import { isomorphicLog } from 'shared/utils/logging-utils'
import { parseCookies } from 'shared/utils/server-utils'
import { PagesAdmin } from '../pagesAdmin/index'
import { serverRequestService } from '../server/services/serverRequestService'

const GRID_PAGES = new Set([
  'a-bathing-ape',
  'adidas',
  'air-jordans',
  'and1',
  'apparel',
  'champion',
  'crocs',
  'curry-brand',
  'fila',
  'men',
  'new-balance',
  'new-releases',
  'nike',
  'off-white',
  'pre-owned',
  'price-drops',
  'sneakers',
  'sneakers-under-250',
  'sneakers-under-350',
  'supreme',
  'women',
  'yeezy',
  'youth',
])

type SlugPageProps = ProductPageProps &
  FilteredGridPageProps & {
    isPdp: boolean
    isPagesAdmin: boolean
    pageData: any
  }

const SlugPage = ({
  data,
  error,
  errorStatus,
  hostname,
  initialSearchState,
  isPdp,
  loading,
  pathname,
  productTemplate,
  resultsState,
  slug,
  trendingResultsState,
  locale,
  isPagesAdmin,
  pageData,
  urlSize,
}: SlugPageProps) => {
  if (isPagesAdmin) {
    return <PagesAdmin pageData={pageData} />
  }
  return isPdp ? (
    <ProductTemplatePage
      hostname={hostname}
      pathname={pathname}
      errorStatus={errorStatus}
      error={error}
      loading={loading}
      productTemplate={productTemplate}
      locale={locale}
      urlSize={urlSize}
    />
  ) : (
    <FilteredGridPage
      data={data}
      error={error}
      hostname={hostname}
      initialSearchState={initialSearchState}
      loading={loading}
      resultsState={resultsState}
      slug={slug}
      trendingResultsState={trendingResultsState}
    />
  )
}

export const getServerSideProps = async (context: FCGetServerSidePropsContext) => {
  const { req, res, query, locale } = context
  const { slug: querySlug } = query
  // converts the NextPageContext.slug type 'string | string[]' -> 'string'
  const slug = Array.isArray(querySlug) ? querySlug[0] : querySlug
  const { jwt, currency } = parseCookies(req)
  const asPath = req.url || ''
  const apolloClient: ApolloClient<NormalizedCacheObject> = createApolloClient(
    {},
    {
      getCsrf: () => null,
      getToken: () => jwt,
      req,
      res,
      currency,
    },
  )

  try {
    const hostname = typeof window === 'undefined' ? req?.headers.host : window.location.hostname
    const pathname = typeof window === 'undefined' ? req?.url : window.location.pathname
    const { data } = await serverRequestService(req, res, 'sneakers', 'no-store').get(
      `/api/v1/pages/${slug}`,
    )
    return {
      props: {
        ...(await getServerPageProps(context)),
        ...JSON.parse(
          JSON.stringify({
            ...(await serverSideConfigs(res, locale)),
            hostname,
            isPdp: false,
            isPagesAdmin: true,
            pageData: data,
            pathname,
            locale,
          }),
        ),
      },
    }
  } catch (error) {
    const err = error as AxiosError
    if (err.response && err.response.status === 404) {
      logger.info(`Not a defined page slug: ${slug}`)
    } else {
      logger.error(error, `Error while retrieving page slug ${slug} from server.`)
    }
  }

  // Check if first slug is not a GRID_PAGE reference
  // If true, then it is PDP. Get props for PDP
  if (slug && !GRID_PAGES.has(slug)) {
    const hostname = typeof window === 'undefined' ? req?.headers.host : window.location.hostname
    const pathname = typeof window === 'undefined' ? req?.url : window.location.pathname
    const urlSize = typeof window === 'undefined' ? req?.query?.size : new URLSearchParams(window.location.search).get('size')
    try {
      const { loading, error, data } = await apolloClient.query({
        query: GET_PRODUCT_TEMPLATE,
        variables: {
          slug,
          countryCode: req?.cookies?.country || res?.locals?.country || DEFAULT_COUNTRY_CODE,
          currencyCode:
            req.cookies.currencyCode || res?.locals?.currencyCode || DEFAULT_CURRENCY_CODE,
        },
      })
      const productTemplate: IProductTemplate = {
        ...get('getProductTemplate', data),
        fetchedServerSide: !!req,
      }
      return {
        props: {
          ...(await getServerPageProps(context)),
          ...JSON.parse(
            JSON.stringify({
              ...(await serverSideConfigs(res, locale)),
              hostname,
              isPdp: true,
              isPagesAdmin: false,
              pathname,
              loading,
              error,
              productTemplate,
              locale,
              urlSize,
            }),
          ),
        },
      }
    } catch (error) {
      const graphqlStatus = get('graphQLErrors[0].extensions.response.status')(error)
      const status = graphqlStatus || 404

      if (!!graphqlStatus) {
        isomorphicLog(
          `Status returned ${graphqlStatus} when rendering product template ${slug} page: ${JSON.stringify(
            error,
          )}`,
        )
      }

      if (status >= 400 && status <= 599 && !isNil(res)) {
        res.statusCode = status
      }

      return {
        props: {
          ...(await getServerPageProps(context)),
          ...JSON.parse(
            JSON.stringify({
              ...(await serverSideConfigs(res, locale)),
              hostname,
              isPdp: true,
              isPagesAdmin: false,
              pathname,
              errorStatus: status,
              locale,
            }),
          ),
        },
      }
    }
  }

  // Is grid page. Get props for grid page (aka PLP)
  Bugsnag.leaveBreadcrumb('FilteredGridPage', {
    asPath,
  })
  let initialSearchState: SearchState = {}

  let path = getDefaultLocalePath(asPath)

  try {
    const { data, loading, error } = await apolloClient.query<
      GetBreadcrumbInfoData,
      GetBreadcrumbInfoVars
    >({
      query: GET_BREADCRUMB_INFO,
      variables: {
        slug: path,
      },
    })
    const {
      algoliaQuery: {
        filters = {},
        numeric_filters: numericFilters = {},
        indexName: index = AlgoliaIndex.Relevance,
        query = '',
        range,
      },
    } = data?.getBreadCrumbInfoBySlug

    if (asPath.includes('?')) {
      initialSearchState = urlToSearchState(
        asPath.includes('?') ? qs.parse(asPath.substring(asPath.indexOf('?') + 1)) : {},
      )
      path = asPath.substring(0, asPath.indexOf('?'))
    }

    const createRefinementList = pipe(omit(['__typename']), omitBy(isNil))

    /**
     * TODO: really should just be using one searchState, need to work on combining them.
     * We have 2 separate searchstates because one is used for the filters in the url, and
     * the other is used for server side render. Filters are added as filter prop for clientside,
     * but need to add query to both searchState for client and server side.
     * searchState is used for SSR
     * initialSearchState is used for some client side set up we have
     */
    const searchState: SearchState = {
      refinementList: {
        ...createRefinementList(filters),
        ...createRefinementList(numericFilters),
      },
      index,
      query,
    }

    if (query) {
      initialSearchState.query = query
    }
    if (range) {
      initialSearchState.range = range
    }

    const hostname = typeof window === 'undefined' ? req?.headers?.host : window.location.hostname

    const { resultsState, trendingResultsState } = await getResultsState(
      initialSearchState,
      searchState,
      30,
    )

    return {
      props: {
        ...(await getServerPageProps(context)),
        ...JSON.parse(
          JSON.stringify({
            ...(await serverSideConfigs(res, locale)),
            data,
            hostname,
            isPdp: false,
            isPagesAdmin: false,
            initialSearchState,
            loading,
            resultsState,
            slug: path,
            trendingResultsState,
            locale,
          }),
        ),
      },
    }
  } catch (error) {
    const graphqlStatus = get('graphQLErrors[0].extensions.response.status')(error)
    const status = graphqlStatus || 500

    if (!!graphqlStatus) {
      isomorphicLog(
        `Status returned ${graphqlStatus} when rendering product list ${asPath} page: ${JSON.stringify(
          error,
        )}`,
      )
    }

    if (status >= 400 && status <= 599 && !isNil(res)) {
      res.statusCode = status
    }

    return {
      props: {
        ...(await getServerPageProps(context)),
        ...(await serverSideConfigs(res, locale)),
        isPdp: false,
        isPagesAdmin: false,
        error: status,
        locale,
      },
    }
  }
}

export default SlugPage
