import BuyNewTogglePanel from './panels/BuyNewTogglePanel'
import BuyConditionalPanel from './panels/BuyConditionalPanel'

interface IShipViewProps {
  openPricingModal: () => void
}

const ShipView = ({ openPricingModal }: IShipViewProps) => {
  return (
    <>
      <BuyNewTogglePanel openPricingModal={openPricingModal} />
      <BuyConditionalPanel />
    </>
  )
}

export default ShipView
