import styled from 'styled-components'
import { useContext } from 'react'

import colors from 'shared/lib/colors'
import DesktopProductPictureCarousel from 'productTemplate/components/DesktopProductPictureCarousel'
import { FavoritesModalDesktop } from 'favorites/FavoritesModalDesktop'
import { LoginPanelModal } from 'favorites/LoginPanelModal'
import { pageWidth } from 'shared/lib/responsiveness'
import ProductBreadcrumbs from 'productTemplate/components/ProductBreadcrumbs'
import ProductDetails from 'productTemplate/components/ProductDetails'
import { ProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import ProductTitle from 'productTemplate/components/ProductTitle'
import { ProductTemplateLayoutProps } from 'productTemplate/types/IProductTemplateLayoutProps'
import media from 'shared/lib/media'
import { renderStart } from 'shared/hocs/render'
import { setIsSaveFavoritesModalOpen } from 'store/favoritesSlice'
import { useAppSelector } from 'store/hooks'
import { useAppDispatch } from 'store/hooks'
import { useOpenProductModal } from 'favorites/hooks/useOpenProductModal'
import { useUser } from 'shared/hooks/useUser'

const ProductTemplateDesktopLayout = (props: ProductTemplateLayoutProps) => {
  const { isAuthenticated } = useUser()
  const { isSaveFavoritesModalOpen } = useAppSelector((state) => state.favorites)
  const { productTemplate } = useContext(ProductTemplateContext)
  const { className } = props
  const { brand, breadcrumbPath, description, name, pictures, productTaxonomy, releaseDate, sku } =
    productTemplate

  const dispatch = useAppDispatch()

  const handleFavoriteClick = () => {
    dispatch(setIsSaveFavoritesModalOpen(true))
  }

  const handleCloseFavoritesModal = () => {
    dispatch(setIsSaveFavoritesModalOpen(false))
  }

  useOpenProductModal()

  return (
    <ProductDesktopLayout className={className}>
      <ProductWrapper>
        <LeftLayout>
          <ProductBreadcrumbs
            breadcrumbPath={breadcrumbPath}
            name={name}
            productTaxonomy={productTaxonomy}
            handleFavoriteClick={handleFavoriteClick}
            productTemplateId={productTemplate?.id}
            productTemplateSlug={productTemplate?.slug}
          />
          <DesktopProductPictureCarousel pictures={pictures} />
        </LeftLayout>
        <RightLayout>
          <ProductTitle
            brand={brand}
            name={name}
            sku={sku}
            description={description}
            releaseDate={releaseDate.shortDisplay}
          />
          <ProductDetails />
        </RightLayout>
      </ProductWrapper>
      {!isAuthenticated && isSaveFavoritesModalOpen && (
        <LoginPanelModal
          handleCloseLoginPanelModal={handleCloseFavoritesModal}
          location="pdp_favorite"
          flow="viewFavorites"
        />
      )}
      {isAuthenticated && isSaveFavoritesModalOpen && (
        <FavoritesModalDesktop
          isApparel={productTemplate?.productCategory !== 'shoes'}
          sizeCategory={productTemplate?.sizeCategory}
          handleCloseModal={handleCloseFavoritesModal}
          productTemplateId={productTemplate?.id}
        />
      )}
    </ProductDesktopLayout>
  )
}
export default renderStart.large(ProductTemplateDesktopLayout)

const ProductDesktopLayout = styled.div`
  margin: 0 auto;
  background-color: ${colors.FC2_WHITE};
  padding-top: 40px;
`

const ProductWrapper = styled.div`
  ${pageWidth}
  display: flex;
  justify-content: space-between;
  margin: 0 34px;
  ${media.large`
    margin: 0 auto;
  `}
`

const LeftLayout = styled.div`
  width: 65%;
`

const RightLayout = styled.div`
  width: 42%;
`
