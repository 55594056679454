import styled from 'styled-components'

import { t } from 'localization'
import Divider from 'shared/components/Divider'
import { ProductQA } from 'shared/dataAttributes'
import { colors } from 'shared/lib'
import { formatMoneyObject } from 'shared/lib/formatMoneyObject'
import { PriceLocalized } from 'shared/types/PriceLocalized'

const TP = 'productTemplate.components.ProductPicker.PriceBreakDown'
type TableRowProps = {
  text?: string
  amount?: string
  dataTestId?: string
  qaAttr?: string
}

type PriceBreakdownProps = {
  markupFee: PriceLocalized
  basePrice: PriceLocalized
  totalPrice: PriceLocalized
}

const PriceBreakdownRow = ({ text, amount, dataTestId, qaAttr }: TableRowProps) => (
  <Row>
    <div data-qa={`${qaAttr}Tag`} data-testid={`${dataTestId}Text`}>
      {text}
    </div>
    <div data-qa={`${qaAttr}Value`} data-testid={`${dataTestId}Value`}>
      {amount}
    </div>
  </Row>
)

const PriceBreakdown = ({ markupFee, basePrice, totalPrice }: PriceBreakdownProps) => (
  <PriceBreakdownChart>
    <PriceBreakdownRow
      qaAttr={ProductQA.PopupListPrice}
      text={t(`${TP}.productPrice`, 'Product Price')}
      amount={formatMoneyObject(basePrice, { showCents: false })}
      dataTestId="listPrice"
    />
    <PriceBreakdownRow
      qaAttr={ProductQA.PopupPriorityProcessing}
      text={t(`${TP}.priorityProcessing`, 'Priority Processing')}
      amount={`+${formatMoneyObject(markupFee, { showCents: false })}`}
      dataTestId="priorityProcessing"
    />
    <StyledDivider />
    <PriceBreakdownRow
      qaAttr={ProductQA.PopupTotalPrice}
      text={t(`${TP}.totalPrice`, 'Total Price')}
      amount={formatMoneyObject(totalPrice, { showCents: false })}
      dataTestId="totalPrice"
    />
  </PriceBreakdownChart>
)

const PriceBreakdownChart = styled.div`
  background-color: ${colors.FC2_OFF_WHITE};
  width: 100%;
  padding: 20px 20px 10px;
  margin-top: 35px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${colors.FC2_DARK_GREY};
  margin-bottom: 10px;
`

const StyledDivider = styled(Divider)`
  margin: 15px 0;
`

export default PriceBreakdown
