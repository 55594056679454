export const mockProductTemplate = {
  brand: 'Nike',
  breadcrumbPath: [
    { slug: 'nike', name: 'Nike', __typename: 'ProductBreadcrumb' },
    {
      slug: 'nike/nike-basketball',
      name: 'Nike Basketball',
      __typename: 'ProductBreadcrumb',
    },
    {
      slug: 'nike/nike-basketball/penny-hardaway',
      name: 'Penny Hardaway',
      __typename: 'ProductBreadcrumb',
    },
  ],
  conditionalSizes: [
    {
      productId: 293784,
      size: { value: 13, display: '13' },
      price: { display: '$6000.00', value: 600000, localizedValue: 600000 },
      thumbnailUrl: null,
      shoeCondition: 'new',
      boxCondition: 'missing lid',
      images: ['aasldfjalsf.png'],
      isInstantShip: false,
      conditions: 'missing lid',
      notes: 'box missing lid',
    },
  ],
  description: 'black/black-laser crimson',
  details: 'black/black-laser crimson',
  fetchedServerSide: false,
  id: 12947,
  isNewInStock: true,
  locations: {
    FCNY: null,
    FCLA: null,
    FCMIA: null,
    FCCHI: null,
  },
  name: 'air foamposite pro prm',
  newCount: 20,
  newHighestPrice: {
    value: 95000,
    __typename: 'Price',
    localizedValue: 95000,
    display: '$950.00',
  },
  newLowestPrice: { value: 2500, __typename: 'Price', localizedValue: 2500, display: '$25' },
  newSizes: [
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 21000,
          display: '$210.00',
          localizedValue: 21000,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 19500,
          display: '$195.00',
          localizedValue: 19500,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 19500,
          display: '$195.00',
          localizedValue: 19500,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 23000,
          display: '$230.00',
          localizedValue: 23000,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 18500,
          display: '$185.00',
          localizedValue: 18500,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 11500,
          display: '$115.00',
          localizedValue: 11500,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 30000,
          display: '$300.00',
          localizedValue: 30000,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 15500,
          display: '$155.00',
          localizedValue: 15500,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 7000,
          display: '$70.00',
          localizedValue: 7000,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 10500,
          display: '$105.00',
          localizedValue: 10500,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 15500,
          display: '$155.00',
          localizedValue: 15500,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 12000,
          display: '$120.00',
          localizedValue: 12000,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 11500,
          display: '$115.00',
          localizedValue: 11500,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 11000,
          display: '$110.00',
          localizedValue: 11000,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 13500,
          display: '$135.00',
          localizedValue: 13500,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 2500,
          display: '$25.00',
          localizedValue: 2500,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 15000,
          display: '$150.00',
          localizedValue: 15000,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
    {
      productTemplateId: 23048,
      size: { display: '16', value: 16 },
      boxCondition: 'new',
      shoeCondition: 'new',
      lowestPriceOption: {
        price: {
          value: 95000,
          display: '$95000',
          localizedValue: 95000,
          __typename: 'Price',
        },
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      instantShipPriceOption: {
        price: null,
        isAvailable: false,
        __typename: 'ProductToggleOption',
      },
      isInstantShip: false,
      __typename: 'NewProductSizeOption',
    },
  ],
  pictures: [
    'https://cdn-staging.flightclub.com/TEMPLATE/042112/1.jpg',
    'https://cdn-staging.flightclub.com/TEMPLATE/042112/2.jpg',
    'https://cdn-staging.flightclub.com/TEMPLATE/042112/3.jpg',
    'https://cdn-staging.flightclub.com/TEMPLATE/042112/4.jpg',
  ],
  pictureUrl: 'https://cdn-staging.flightclub.com/TEMPLATE/042112/1.jpg',
  productCategory: 'shoes',
  releaseDate: {
    value: '2014-04-19T23:59:59.999Z',
    __typename: 'Date',
    shortDisplay: '4/19/14',
  },
  silhouette: 'foamposite Pro',
  sizeCategory: 'men',
  sizePickerDefault: {
    size: {
      value: 3,
      display: '3',
    },
    new: {
      size: {
        value: 3,
        display: '3',
      },
      shoeCondition: 'new_no_defects',
      boxCondition: 'good_condition',
      lowestPriceCents: {
        display: '$9,300',
        localizedValue: 9300,
        value: 9300,
      },
      instantShipLowestPriceCents: {
        display: '$12,300',
        localizedValue: 12300,
        value: 12300,
      },
    },
  },
  sizePickerOptions: [
    {
      size: {
        value: 3,
        display: '3',
      },
      new: {
        size: {
          value: 3,
          display: '3',
        },
        shoeCondition: 'new_no_defects',
        boxCondition: 'good_condition',
        lowestPriceCents: {
          display: '$9,300',
          localizedValue: 9300,
          value: 9300,
        },
        instantShipLowestPriceCents: {
          display: '$12,300',
          localizedValue: 12300,
          value: 12300,
        },
      },
    },
    {
      size: {
        value: 7.5,
        display: '7.5',
      },
      new: {
        size: {
          value: 7.5,
          display: '7.5',
        },
        shoeCondition: 'new_with_defects',
        boxCondition: 'good_condition',
        lowestPriceCents: {
          display: '$1,000.00',
          localizedValue: 100000,
          value: 100000,
        },
        instantShipLowestPriceCents: {
          display: '$1,275.00',
          localizedValue: 127500,
          value: 127500,
        },
      },
    },
  ],
  sku: '616750 001',
  slug: 'air-foamposite-pro-prm-black-black-laser-crimson-042112',
  story: 'once upon a time, there was a shoe you had to have...',
  storyHtml: 'once upon a time, there was a shoe you had to have...',
  usedLowestPrice: {
    value: 10000,
    localizedValue: 10000,
    display: '$100',
    __typename: 'Price',
  },
  __typename: 'ProductTemplate',
}
