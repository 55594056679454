import Carousel from 'shared/components/Carousel'
import { ProductQA } from 'shared/dataAttributes'

type Props = {
  pictures: string[]
}

const MobileProductPictureCarousel = ({ pictures }: Props) => {
  return (
    <Carousel
      data-qa={ProductQA.MobileMainCarouselAllImages}
      pictureUrls={pictures}
    />
  )
}

export default MobileProductPictureCarousel
