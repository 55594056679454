import { gql, useQuery } from '@apollo/client'
import qs from 'qs'
import { FC, useState, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

import ErrorBanner from 'errors/components/ErrorBanner'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { t } from 'localization'
import { logAddToCart } from 'mParticle/ecommerceMParticleEvents'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { useProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import { IOrderInfo } from 'productTemplate/types/IOrderInfo'
import { ProductType } from 'productTemplate/types/ProductType'
import { Button } from 'shared/components/Button'
import Carousel from 'shared/components/Carousel'
import SideSheetModal from 'shared/components/SideSheetModal'
import Spinner from 'shared/components/Spinner'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { ProductQA } from 'shared/dataAttributes'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { colors, fonts, media } from 'shared/lib'
import { IProductSizeOption } from 'shared/types/IProductSizeOption'
import { trackSnapchatAddCart } from 'tracking/snapchatService'

const TP = 'productTemplate.components.ProductPicker'

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  flex: 1;
  background: ${colors.FC2_OFF_WHITE};
`
const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  min-width: 100%;
  width: 100%;
`
const ListItem = styled.li`
  width: 100%;
  margin-bottom: 10px;
`

export const GET_USED_PRODUCTS = gql`
  query getUsedProducts($slug: String, $countryCode: String, $size: Float) {
    getUsedProducts(slug: $slug, countryCode: $countryCode, size: $size) {
      size {
        value
        display
      }
      price {
        value
        localizedValue
        display(useGrouping: true, hideCents: true)
      }
      shoeCondition
      boxCondition
      productId
      thumbnailUrl
      images
      isInstantShip
      conditions
      notes
    }
  }
`

const UsedProductModalContent: FC = () => {
  const { productTemplate, selectedSize } = useProductTemplateContext()
  const hideHighPricedUsedProduct = useFeatureFlag(FeatureFlag.HIDE_HIGH_PRICED_USED_PRODUCT)
  const { currencyCode: selectedCurrency } = useShoppingRegionContext()
  const { country: selectedCountryCode } = useShoppingRegionContext()
  const { data, loading, error } = useQuery(GET_USED_PRODUCTS, {
    variables: {
      slug: productTemplate?.slug,
      countryCode: selectedCountryCode,
      size: selectedSize?.size.value!,
    },
  })
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false)

  if (error) {
    return (
      <ErrorBanner
        data-testid="used-product-modal-error-msg"
        message={t(
          `${TP}.errorBannerMessage`,
          'Unable to get available used shoes. Please try again or reach out to support@flightclub.com',
        )}
      />
    )
  }

  return (
    <Spinner data-testid="spinner" showSpinner={loading || isCheckoutLoading}>
      <ContentWrapper>
        <List>
          {!!data?.getUsedProducts?.length &&
            data.getUsedProducts.map((usedShoe: IProductSizeOption) => {
              if (
                hideHighPricedUsedProduct &&
                usedShoe.price.value >= selectedSize?.new?.instantShipLowestPriceCents?.value!
              ) {
                return null
              }
              return (
                <ListItem key={usedShoe.productId}>
                  <UsedShoeDetails
                    brand={productTemplate.brand}
                    currencyCode={selectedCurrency}
                    details={productTemplate.details}
                    name={productTemplate.name}
                    pictureUrl={productTemplate.pictureUrl}
                    sku={productTemplate.sku}
                    slug={productTemplate.slug}
                    usedShoe={usedShoe}
                    setIsCheckoutLoading={setIsCheckoutLoading}
                  />
                </ListItem>
              )
            })}
        </List>
      </ContentWrapper>
    </Spinner>
  )
}

const Wrapper = styled.figure`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 100%;
  margin: 0;
  padding: 20px;
  background-color: ${colors.FC2_WHITE};
  ${media.large`
    padding: 30px;
  `}
`
const Footer = styled.figcaption`
  display: flex;
  flex-direction: column;
`
const Row = styled.div<{ hasNotes: boolean }>`
  display: flex;
  margin-bottom: ${({ hasNotes }) => (hasNotes ? '20' : '0')}px;
  justify-content: space-between;
`
const RowLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div:first-child {
    ${fonts.SUBTITLE_2}
    margin-bottom: 5px;
  }
  div:nth-child(2) {
    ${fonts.BODY_TEXT}
  }
`
const RowRight = styled.div`
  display: flex;
  justify-content: space-between;
`
const CheckoutButton = styled(Button)`
  min-width: 105px;
  line-height: 45px;
  margin: 0;
  padding: 0;
`
const Notes = styled.div`
  ${fonts.BODY_TEXT}
  padding: 20px;
  background-color: ${colors.FC2_OFF_WHITE};
`
const CarouselContainer = styled.div`
  margin-bottom: 30px;
`

const formatForOrderInfo = (selectedSize: IProductSizeOption): IOrderInfo => ({
  instantShip: selectedSize.isInstantShip,
  condition: selectedSize.shoeCondition,
  expectedPriceCents: selectedSize.price.value,
  localizedExpectedPriceCents: selectedSize.price.localizedValue,
  productType: ProductType.Used,
  size: {
    display: selectedSize.size.display,
    value: selectedSize.size.value,
  },
  productTemplateId: null,
  productId: selectedSize.productId,
})

interface IDataLogCartAdd {
  price: number
  size: string
  instantShip: boolean
  condition: string
  is_apparel_product: boolean
  currency_code?: string
  product_template_slug: string | string[]
}

interface IUsedShoeDetailsProps {
  brand: string
  currencyCode?: string
  details: string
  name: string
  pictureUrl: string
  sku: string
  slug: string
  usedShoe: IProductSizeOption
  setIsCheckoutLoading: Dispatch<SetStateAction<boolean>>
}

const UsedShoeDetails = ({
  brand,
  currencyCode = 'usd',
  details,
  name,
  pictureUrl,
  sku,
  slug,
  usedShoe,
  setIsCheckoutLoading,
}: IUsedShoeDetailsProps) => {
  const { images, notes, price, size, shoeCondition, isInstantShip } = usedShoe
  const isMarketPricing = useFeatureFlag(FeatureFlag.MARKET_PRICING)
  const shippingDelays = useFeatureFlag(FeatureFlag.SHIPPING_DELAY)

  const handleCheckoutButtonClick = () => {
    try {
      setIsCheckoutLoading(true)
      sendTrackingEvent<IDataLogCartAdd>('CART_ADD', {
        condition: shoeCondition,
        currency_code: currencyCode,
        instantShip: isInstantShip,
        is_apparel_product: false, // will need to add to size option
        price: price.value,
        product_template_slug: slug,
        size: size.display,
      })
      logAddToCart({
        brandName: brand,
        condition: shoeCondition?.toLowerCase(),
        name,
        priceCents: price.value,
        sku,
      })
      trackSnapchatAddCart(trackSnapchatAddCart({ sku }))
    } catch (error) {
      setIsCheckoutLoading(false)
    }

  }

  return (
    <Wrapper>
      <CarouselContainer>
        {!!images?.length && (
          <Carousel
            data-testid="usedProductCarousel"
            pictureUrls={images}
            navigationType="bullets"
          />
        )}
      </CarouselContainer>
      <Footer>
        <Row hasNotes={!!notes}>
          <RowLeft>
            <div data-qa={ProductQA.BuyUsedRowTitle}>{t(`${TP}.used`, 'Used')}</div>
            {!shippingDelays && (
              <div data-qa={ProductQA.BuyUsedRowShipingEstimate}>
                {isMarketPricing
                  ? t(`${TP}.shipToVerify`, 'Ships to us first for verification')
                  : t(`${TP}.instantShip`, 'Pre-verified and ready to ship')}
              </div>
            )}
          </RowLeft>
          <RowRight>
            <CheckoutButton
              buttonType="primary2"
              $fill
              href={`/checkout?${qs.stringify({
                ...formatForOrderInfo(usedShoe),
                details,
                mainPictureUrl: pictureUrl,
                name,
                sizeDisplay: usedShoe.size.display,
              })}`}
              onClick={handleCheckoutButtonClick}
              qaAttr={ProductQA.BuyUsedCheckoutButton}
            >
              {price.display}
            </CheckoutButton>
          </RowRight>
        </Row>
        {notes && (
          <Notes data-qa={ProductQA.BuyUsedRowNotes}>
            {t(`${TP}.notes`, {
              defaultValue: 'Notes: {notes}',
              notes,
            })}
          </Notes>
        )}
      </Footer>
    </Wrapper>
  )
}

export default UsedProductModalContent
