import Head from 'next/head'
import ReactMarkdown from 'react-markdown'
import rehypeSlug from 'rehype-slug'
import styled from 'styled-components'

import { serverSideConfigs, t } from 'localization'
import { GetServerSideProps } from 'next'
import { PagesQA } from 'shared/dataAttributes'
import { colors, media, styles } from 'shared/lib'

export const PagesAdmin = ({ pageData }) => {
  return (
    <>
      <Head>
        {/* i18next-extract-disable-next-line */}
        <title>{t(`pages.${pageData?.slug}.pageTitle`, `${pageData?.title} | Flight Club`)}</title>
      </Head>
      <Wrapper data-qa={PagesQA.DynamicSlug}>
        <ReactMarkdown rehypePlugins={[rehypeSlug]}>{pageData?.content}</ReactMarkdown>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  background-color: ${colors.FC2_WHITE};
  height: 100%;
  padding: ${styles.mobilePageLayoutPadding};
  ${media.large`
    padding: 80px 150px 100px;
  `}
`
export const getServerSideProps: GetServerSideProps = async ({ res, locale }) => {
  return {
    props: {
      ...(await serverSideConfigs(res, locale)),
    },
  }
}
