import styled from 'styled-components'
import { fonts, colors } from 'shared/lib'
import { SVGIcon } from 'shared/components/Icons'
import LowestPricePanel from '../ProductPicker/panels/LowestPricePanel'
import FastestToYouPanel from '../ProductPicker/panels/FastestToYouPanel'
import { ProductQA } from 'shared/dataAttributes'
import { t } from 'localization'

const TP = 'productTemplate.components.ProductPicker.PricingInfoModal'

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.FC2_WHITE};
  border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
`

const Title = styled.div`
  ${fonts.SUBTITLE_1};
  text-transform: uppercase;
  padding: 20px;
`

const CloseButton = styled.button`
  padding: 20px;
`

const XIcon = styled(SVGIcon).attrs({
  size: 'small',
  name: 'closeX',
})``

interface IPricingInfoModal {
  closePricingModal: () => void
}

const PricingInfoModal = ({ closePricingModal }: IPricingInfoModal) => {
  return (
    <>
      <ModalHeader>
        <Title data-qa={ProductQA.PopupFlightClubPricingtitle}>
          {t(`${TP}.PopupFlightClubPricingtitle`, 'Flight Club Pricing')}
        </Title>
        <CloseButton
          data-qa={ProductQA.PopupFlightClubPricingCloseButton}
          onClick={closePricingModal}
        >
          <XIcon data-testid="responsiveSideSheetCloseIcon" />
        </CloseButton>
      </ModalHeader>
      <LowestPricePanel />
      <FastestToYouPanel />
    </>
  )
}

export default PricingInfoModal
