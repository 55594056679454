import { MutableRefObject, useState } from 'react'

import { ClientOnlyPortal } from 'shared/components/ClientOnlyPortal'
import { useClickOutside } from 'shared/hooks'
import { SlideOutPanel } from 'shared/components/SlideOutPanel'

import { FavoritesModalContents } from './FavoritesModalContents'

type FavoritesModal = {
  isApparel: boolean
  sizeCategory: string
  handleCloseModal: () => void
  productTemplateId: string
  withBackdrop?: boolean
}

export const FavoritesModalMobile = ({
  isApparel,
  sizeCategory,
  handleCloseModal,
  productTemplateId,
  withBackdrop,
}: FavoritesModal) => {
  const [renderPortal, setRenderPortal] = useState(true)
  const ref: MutableRefObject<HTMLDivElement | null> = useClickOutside(() => setRenderPortal(false))

  const handleClose = () => {
    setRenderPortal(false)
    setTimeout(() => handleCloseModal(), 300)
  }

  const onAnimationStart = () => {
    if (!renderPortal) {
      setTimeout(() => handleCloseModal(), 300)
    }
  }

  return (
    <ClientOnlyPortal
      selector=".main-page-layout"
      withBackdrop={withBackdrop}
      itemsAlignment="end"
      id="favorites-modal-mobile"
    >
      <SlideOutPanel
        ref={ref}
        className={renderPortal ? 'enter' : 'exit'}
        onAnimationStart={onAnimationStart}
      >
        <FavoritesModalContents
          isApparel={isApparel}
          sizeCategory={sizeCategory}
          handleClose={handleClose}
          productTemplateId={productTemplateId}
        />
      </SlideOutPanel>
    </ClientOnlyPortal>
  )
}
