import React from 'react'
import PanelBox from 'shared/components/Panel/PanelBox'
import PDPDivider from 'shared/components/PDPDivider'
import styled from 'styled-components'

interface IModalPanelProps {
  qaAttr?: string
  hasDivider?: boolean
  children: React.ReactNode
}

const ModalPanelBox = styled(PanelBox)`
  padding: 30px 20px;
`

const ModalPanel = ({ children, qaAttr, hasDivider = true }: IModalPanelProps) => {
  return (
    <>
      {hasDivider && <PDPDivider />}
      <ModalPanelBox data-qa={`${qaAttr}Panel`} $fill>
        {children}
      </ModalPanelBox>
      {hasDivider && <PDPDivider />}
    </>
  )
}

export default ModalPanel
