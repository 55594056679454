import { getProductSizeCategory } from './getProductSizeCategory'
import { t } from 'localization'

interface ITranslationPath {
  basePath: string
  youthLabelPath: string
  defaultLabelPath: string
  fallbackLabel: string
}

interface IGetSizeLabel {
  sizeCategory: string
  isApparel: boolean
  TP?: ITranslationPath
  type?: string
}

const defaultTPOptions = {
  basePath: 'productTemplate.components.ProductPicker.ProductPicker',
  youthLabelPath: 'buyNewSectionLabelYouth',
  defaultLabelPath: 'buyNewSectionLabel',
  fallbackLabel: 'Select US {sizeCategory} Size',
}

export const getSizeLabel = ({ sizeCategory, isApparel, TP = defaultTPOptions }: IGetSizeLabel) => {
  switch (sizeCategory) {
    case 'youth':
    case 'infant':
      return t(`${TP.basePath}.${TP.youthLabelPath}`, {
        defaultValue: TP.fallbackLabel,
        sizeCategory: getProductSizeCategory(sizeCategory),
      })
    default:
      return t(`${TP.basePath}.${TP.defaultLabelPath}`, {
        defaultValue: TP.fallbackLabel,
        sizeCategory: isApparel ? '' : `${getProductSizeCategory(sizeCategory)}'s`,
      })
  }
}
