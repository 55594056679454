import styled from 'styled-components'
import { colors, media } from 'shared/lib'

const PDPDivider = styled.div`
  height: 10px;
  border-radius: 1px;
  background-color: ${colors.FC2_OFF_WHITE};
  ${media.large`
    position: relative;
    width: 100%;
    margin: 0;
  `}
`

export default PDPDivider
