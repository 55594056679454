import IAlgoliaFilters from 'search/types/IAlgoliaFilters'
import { each } from 'lodash'
import { getActiveFilterTypes } from './formatAlgoliaFilters'
import { SearchFiltersRefinement } from 'search'
import { isClothingPresent, isAccessoriesPresent } from './isCategoryPresent'

interface IOverrides {
  hideBrand: boolean
}

const defaultOverrides = {
  hideBrand: false,
}

const hideFilters = (
  filters: Partial<IAlgoliaFilters>,
  overrides: IOverrides = defaultOverrides,
): Set<string> => {
  const result: Set<string> = new Set()
  if (!filters) {
    return result
  }
  const activeFilterTypes = getActiveFilterTypes(filters)
  each(activeFilterTypes, filterType => {
    if (filterType === SearchFiltersRefinement.Condition) {
      result.add(SearchFiltersRefinement.Condition)
    }
    if (filterType === SearchFiltersRefinement.Brand) {
      result.add(SearchFiltersRefinement.Brand)
    }
    if (filterType === SearchFiltersRefinement.Silhouette && filters[filterType].length === 1) {
      result.add(filterType)
      result.add(SearchFiltersRefinement.Brand)
    }
    if (overrides.hideBrand) {
      result.add(SearchFiltersRefinement.Brand)
    }
    if (filterType === 'single_gender') {
      if (filters.single_gender[0] === 'men') {
        result.add(SearchFiltersRefinement.WomenSizes)
        result.add(SearchFiltersRefinement.YouthSizes)
      }
      if (filters.single_gender[0] === 'youth') {
        result.add(SearchFiltersRefinement.WomenSizes)
        result.add(SearchFiltersRefinement.MenSizes)
      }
      if (filters.single_gender[0] === 'women') {
        result.add(SearchFiltersRefinement.YouthSizes)
        result.add(SearchFiltersRefinement.MenSizes)
      }
    }
  })
  if (
    isClothingPresent(filters.product_category) ||
    isAccessoriesPresent(filters.product_category)
  ) {
    result.add(SearchFiltersRefinement.Condition)
  } else {
    result.add(SearchFiltersRefinement.PresentationSizes)
  }
  return result
}

export default hideFilters
