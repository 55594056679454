import { t } from 'localization'
import styled from 'styled-components'

import { ProductQA } from 'shared/dataAttributes'
import { colors, media, fonts } from 'shared/lib'
import createSanitizedHtmlObject from 'shared/lib/sanitizeHtml'

const TP = 'productTemplate.components.ProductDecription'

export interface IProductDescriptionProps {
  storyHtml: string
  sku: string
  description: string
  releaseDate: string
}

const ProductDescription = ({
  sku,
  description,
  releaseDate,
  storyHtml,
}: IProductDescriptionProps) => {
  return (
    <ProductDescriptionLayout>
      <ProductDescriptionLabel
        data-qa={ProductQA.ProductDescriptionTitle}
        data-testid="productDescriptionLabel"
      >
        {t(`${TP}.aboutThisProduct`, 'About this product')}
      </ProductDescriptionLabel>
      <ProductDescriptionText
        data-qa={ProductQA.ProductDescriptionText}
        dangerouslySetInnerHTML={createSanitizedHtmlObject(storyHtml)}
        data-testid="productDescriptionText"
      />
      <ProductNutrition
        data-qa={ProductQA.ProductInformationDetails}
        data-testid="productNutrition"
      >
        <span data-qa={ProductQA.ProductSku}>{sku && `SKU: ${sku}`}</span>
        <br />
        <span data-qa={ProductQA.ProductColorway}>{description && `Colorway: ${description}`}</span>
        <br />
        <span data-qa={ProductQA.ProductReleaseDate}>
          {releaseDate && `Release Date: ${releaseDate}`}
        </span>
      </ProductNutrition>
    </ProductDescriptionLayout>
  )
}

const ProductDescriptionLayout = styled.div`
  padding: 50px 0 30px;
  ${media.large`
    padding: 30px 0 0;
  `};
`

const ProductDescriptionLabel = styled.label`
  ${fonts.HEADER_2};
  color: ${colors.FC2_BLACK};
  ${media.large`
    ${fonts.SUBTITLE_2};
    color: ${colors.FC2_BLACK};
  `};
`

const ProductDescriptionText = styled.div`
  ${fonts.BODY_TEXT};
  color: ${colors.FC2_BLACK};
`

const ProductNutrition = styled.h3`
  font-weight: unset;
  margin-top: 20px;
  text-transform: capitalize;
  ${fonts.BODY_TEXT};
`

export default ProductDescription
