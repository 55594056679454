import ProductTemplateDesktopLayout from 'productTemplate/components/ProductTemplateDesktopLayout'
import ProductTemplateMobileLayout from 'productTemplate/components/ProductTemplateMobileLayout'

const ProductTemplateLayout = () => {
  return (
    <>
      <ProductTemplateMobileLayout />
      <ProductTemplateDesktopLayout />
    </>
  )
}

export default ProductTemplateLayout
