import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { t } from 'localization'
import ModalPanel from 'productTemplate/components/ModalPanel'
import ModalPanelText from 'productTemplate/components/ModalPanelText'
import ModalPanelTitle from 'productTemplate/components/ModalPanelTitle'
import { InstantShipToggle } from 'productTemplate/components/ProductPicker/InstantShipToggle'
import { useProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import { isConditional } from 'server/utils/productTemplate/productVariantUtils'
import { LinkCTA } from 'shared/components/Links'
import ProductQA from 'shared/dataAttributes/ProductQA'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import styled from 'styled-components'

const TP = 'productTemplate.components.ProductPicker.panels.BuyNewTogglePanel'

type BuyNewTogglePanelProps = {
  openPricingModal: () => void
}

const BuyNewTogglePanel = ({ openPricingModal }: BuyNewTogglePanelProps) => {
  const hideLearnAboutOurPricing = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_PDP_HIDE_FASTEST_TO_YOU)
  const {
    productTemplate: { specialType },
    selectedSize,
    isApparel,
  } = useProductTemplateContext()

  if (isConditional(selectedSize)) {
    return null
  }
  const isVintage = specialType === 'vintage'

  const newLowestPrice = selectedSize?.new?.lowestPriceCents
  const newInstantShipPrice = selectedSize?.new?.instantShipLowestPriceCents
  const showPricingDetails = newLowestPrice && newInstantShipPrice && !isApparel

  return (
    <ModalPanel hasDivider={false} qaAttr={ProductQA.BuyNewToggle}>
      <TitleWrapper>
        <ModalPanelTitle data-qa={ProductQA.ShipBuyNewSectionLabel}>
          {t(`${TP}.shipBuyNewSectionLabel`, 'Buy New')}
        </ModalPanelTitle>
        {showPricingDetails && !hideLearnAboutOurPricing && (
          <LinkCTA
            qaAttr={ProductQA.ShipBuyNewLearnAboutPricingLink}
            dataTestId="pricingInfoLink"
            color="grey"
            onClick={openPricingModal}
          >
            {t(`${TP}.pricingInfoLink`, 'Learn about our pricing')}
          </LinkCTA>
        )}
      </TitleWrapper>
      {isVintage && (
        <ModalPanelTextPadded>
          {t(
            `${TP}.modalPanelTextPadded`,
            'This sneaker is vintage and may not be suitable for wear due to age.',
          )}
        </ModalPanelTextPadded>
      )}
      <InstantShipToggle
        newLowestPrice={newLowestPrice}
        newInstantShipPrice={newInstantShipPrice}
        openPricingModal={openPricingModal}
      />
    </ModalPanel>
  )
}

const ModalPanelTextPadded = styled(ModalPanelText)`
  margin-top: 7px;
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export default BuyNewTogglePanel
