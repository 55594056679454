import Cookies from 'js-cookie'
import { useState } from 'react'
import styled from 'styled-components'

import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { useTranslation } from 'next-i18next'
import { Trans } from 'react-i18next'
import Button from 'shared/components/Button'
import { ClientOnly } from 'shared/components/ClientOnly'
import { LocationPreferencesModal } from 'shared/components/Storytelling/LocationPreferencesModal'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { STORAGE } from 'shared/enums/SitePreferences'
import useCurrency from 'shared/hooks/useCurrency'

const TP = 'productTemplate.components.ProductPicker.ShippingRegionSelector'

export const ShoppingRegionSelector = () => {
  const tv = useTranslation()
  const [isLocationPreferenceModalOpen, setIsLocationPreferenceModalOpen] = useState(false)
  const { availableCurrencies, getCurrenciesErrors } = useCurrency()

  const [errorMessage, setErrorMessage] = useState(
    !!getCurrenciesErrors?.graphQLErrors?.length
      ? getCurrenciesErrors.graphQLErrors[0]?.message
      : '',
  )

  const { countryName, availableCountries } = useShoppingRegionContext()
  const displayCountry = countryName || t(`${TP}.unitedStates`, 'United States')

  const pdpGlobalPricingMessageEnabled = useFeatureFlag(
    FeatureFlag.TEMP_WEB_FC_PDP_GLOBAL_PRICING_COPY,
  )

  // TODO: find a better way to do this
  const globalPricingBodyCopy = availableCountries?.filter((country) => {
    if (countryName === country.name) {
      return country.globalPricingBodyCopy
    }
  })[0]?.globalPricingBodyCopy
  // TODO: end

  const openLocationPreferenceModal = () => {
    const currencyCookie = Cookies.get(STORAGE.CURRENCYCODE)
    const selectedCurrencySymbolFromCode = availableCurrencies?.filter(
      (currency) => currency.isoCode === currencyCookie,
    )[0]
    sendTrackingEvent('LOCATION_PREFERENCES_VIEW', {
      location: 'pdp',
      page: window?.location?.href,
      shopping_region: Cookies.get(STORAGE.COUNTRYNAME),
      currency: `${currencyCookie}${selectedCurrencySymbolFromCode?.symbol}`,
      language: '',
    })
    setIsLocationPreferenceModalOpen(true)
  }

  const closeCurrencyModal = () => {
    setIsLocationPreferenceModalOpen(false)
    setErrorMessage('')
  }

  return (
    <>
      <ClientOnly>
        <ShoppingRegionText>
          <Trans
            i18nKey={`${TP}.shoppingFrom`}
            t={tv.t}
            defaults="Shopping from <0>{country}</0>. "
            values={{ country: displayCountry }}
            components={[
              <Button
                data-qa="pdp-region-modal-button"
                buttonType="link"
                onClick={openLocationPreferenceModal}
              />,
            ]}
          />
          {globalPricingBodyCopy && pdpGlobalPricingMessageEnabled && (
            <span data-qa="pdp-region-modal-text">{globalPricingBodyCopy}</span>
          )}
        </ShoppingRegionText>
      </ClientOnly>
      <LocationPreferencesModal
        isOpen={isLocationPreferenceModalOpen}
        analyticsLocation="pdp"
        closeCurrencyModal={closeCurrencyModal}
        errorMessage={errorMessage}
      />
    </>
  )
}

const ShoppingRegionText = styled.div`
  margin: 20px 0 10px 0;
  color: #000;
`
