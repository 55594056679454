/* tslint:disable */
/**
 * [startCase]
 *  very similar to lodash method startCase, minus the removal of ALL special characters.
 * @param  {string} facetValue  a string formatted like so 'new-balance' that needs to be in titlecase and have its dashes removed
 * @return {string}             returns a string with the first letter of each word capitalized and only removes dashes and no other special characters
 * ...('chloé') -> 'Chloé'
 * ...('nike') -> Nike
 * ...('ultra boost 2.0') -> 'Ultra Boost 2.0'
 */
export function startCase(facetValue: string): string {
  try {
    return facetValue
      .replace(/-/g, ' ')
      .split(' ')
      .reduce((acc, curr) => {
        return `${acc ? `${acc} ` : ''}${curr.charAt(0).toUpperCase() + curr.slice(1)}`
      }, '')
  } catch (err) {
    return facetValue
  }
}
