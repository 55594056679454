import { includes } from 'lodash/fp'

const isCategoryPresent = (specificCategory: string) => (productCategories?: string[]): boolean => {
  if (!productCategories) {
    return false
  }
  return includes(specificCategory, productCategories)
}

export const isClothingPresent = isCategoryPresent('clothing')
export const isAccessoriesPresent = isCategoryPresent('accessories')
export default isCategoryPresent
