import { MutableRefObject } from 'react'

import { ClientOnlyPortal } from 'shared/components/ClientOnlyPortal'
import { useClickOutside } from 'shared/hooks'

import { FavoritesModalContents } from './FavoritesModalContents'

type FavoritesModal = {
  isApparel: boolean
  sizeCategory: string
  handleCloseModal: () => void
  productTemplateId: string
  withBackdrop?: boolean
}

export const FavoritesModalDesktop = ({
  isApparel,
  sizeCategory,
  handleCloseModal,
  productTemplateId,
  withBackdrop,
}: FavoritesModal) => {
  const ref: MutableRefObject<HTMLDivElement | null> = useClickOutside(() => handleCloseModal())

  return (
    <ClientOnlyPortal
      selector=".main-page-layout"
      withBackdrop={withBackdrop}
      itemsAlignment="center"
      id="favorites-modal-desktop"
    >
      <FavoritesModalContents
        ref={ref}
        isApparel={isApparel}
        sizeCategory={sizeCategory}
        handleClose={handleCloseModal}
        productTemplateId={productTemplateId}
      />
    </ClientOnlyPortal>
  )
}
