import styled from 'styled-components'
import { useContext } from 'react'

import colors from 'shared/lib/colors'
import { FavoriteButton } from 'favorites/FavoriteButton'
import { FavoritesModalMobile } from 'favorites/FavoritesModalMobile'
import { LoginPanelModal } from 'favorites/LoginPanelModal'

import MobileProductPictureCarousel from 'productTemplate/components/MobileProductPictureCarousel'
import ProductDetails from 'productTemplate/components/ProductDetails'
import { ProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import ProductTitle from 'productTemplate/components/ProductTitle'
import { ProductTemplateLayoutProps } from 'productTemplate/types/IProductTemplateLayoutProps'
import { renderUntil } from 'shared/hocs/render'
import { setIsSaveFavoritesModalOpen } from 'store/favoritesSlice'
import { styles } from 'shared/lib'
import { useAppDispatch } from 'store/hooks'
import { useAppSelector } from 'store/hooks'
import { useOpenProductModal } from 'favorites/hooks/useOpenProductModal'
import { useUser } from 'shared/hooks/useUser'

const ProductTemplateMobileLayout = (props: ProductTemplateLayoutProps) => {
  const { isAuthenticated } = useUser()
  const { isSaveFavoritesModalOpen } = useAppSelector((state) => state.favorites)
  const { productTemplate } = useContext(ProductTemplateContext)
  const { className } = props
  const dispatch = useAppDispatch()

  const handleFavoriteClick = () => {
    dispatch(setIsSaveFavoritesModalOpen(true))
  }

  const handleCloseModal = () => {
    dispatch(setIsSaveFavoritesModalOpen(false))
  }

  useOpenProductModal()

  return (
    <ProductMobileLayout className={className}>
      <div>
        <ProductTitle
          brand={productTemplate.brand}
          name={productTemplate.name}
          sku={productTemplate.sku}
          description={productTemplate.description}
          releaseDate={productTemplate.releaseDate.shortDisplay}
        />
        <FavoriteButton
          onClick={handleFavoriteClick}
          productTemplateId={productTemplate?.id}
          productTemplateSlug={productTemplate?.slug}
        />
        <MobileProductPictureCarousel pictures={productTemplate.pictures} />
      </div>
      <ProductInfo>
        <ProductDetails />
      </ProductInfo>

      {!isAuthenticated && isSaveFavoritesModalOpen && (
        <LoginPanelModal
          handleCloseLoginPanelModal={handleCloseModal}
          location="pdp_favorite"
          flow="viewFavorites"
        />
      )}
      {isAuthenticated && isSaveFavoritesModalOpen && (
        <FavoritesModalMobile
          isApparel={productTemplate?.productCategory !== 'shoes'}
          sizeCategory={productTemplate?.sizeCategory}
          handleCloseModal={handleCloseModal}
          productTemplateId={productTemplate?.id}
        />
      )}
    </ProductMobileLayout>
  )
}
export default renderUntil.large(ProductTemplateMobileLayout)

const ProductMobileLayout = styled.div`
  background-color: ${colors.FC2_WHITE};
  padding: 30px ${styles.mobilePageLayoutPadding} 0;
`

const ProductInfo = styled.div`
  display: flex;
  justify-content: center;
`
