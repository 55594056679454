import styled from 'styled-components'

import { SizeOptionWrapper } from 'productTemplate/components/ProductPicker/ProductPickerStyles'
import { ProductQA } from 'shared/dataAttributes'
import { colors, fonts } from 'shared/lib'
import { sizes } from 'shared/lib/media'
import { PTListsSizePickerOption } from 'shared/types/ProductTemplateLists'

type SizePickerProps = {
  sizePickerOptions: PTListsSizePickerOption[]
  onChange: (option: PTListsSizePickerOption) => void
  selectedSizes?: number[]
}

export const SizePicker = ({
  sizePickerOptions,
  onChange,
  selectedSizes = [],
}: SizePickerProps) => {
  const isSelected = (option: PTListsSizePickerOption) => {
    if (selectedSizes?.length) {
      return !!selectedSizes.find((selectionValue) => option.size === selectionValue)
    }
    return false
  }

  return (
    <SizeOptionWrapper data-qa={ProductQA.DesktopSizePicker}>
      {sizePickerOptions.map((option: PTListsSizePickerOption) => (
        <SizeOptionTile isSelected={isSelected(option)} key={option.presentationValue}>
          <label>
            {option.presentationValue}
            <input
              data-qa="SizeOptionTile"
              type="checkbox"
              value={option?.size}
              onChange={() => onChange(option)}
            />
          </label>
        </SizeOptionTile>
      ))}
    </SizeOptionWrapper>
  )
}

export const SizeOptionTile = styled.div<{ isSelected: boolean }>`
  ${fonts.SUBTITLE_2}
  line-height: 42px;
  border: 1.5px solid
    ${({ isSelected }) => (isSelected ? colors.FC2_BLACK : colors.FC2_LIGHTEST_GREY)};
  > label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    > input {
      color: ${colors.FC2_WHITE};
      display: none;
    }
  }

  @media (min-width: ${sizes.large / 16}em) {
    &:hover {
      border: 1.5px solid
        ${({ isSelected }: { isSelected: boolean }) =>
          isSelected ? colors.FC2_BLACK : colors.FC2_GREY};
    }
  }
`
