import { FunctionComponent } from 'react'
import { AfterPayIcon, AffirmIcon, KlarnaIcon, SVGIcon } from 'shared/components/Icons'
import { colors } from 'shared/lib'
import styled from 'styled-components'
import Modal from 'shared/components/Modal'
import { t } from 'localization'
import { Trans } from 'react-i18next'
import { useTranslation } from 'next-i18next'

const TP = 'productTemplate.components.BuyNowPayLaterModal'

type IBuyNowPayLaterModalProps = {
  isBuyNowPayLaterModalOpen: boolean
  handleCloseBuyNowPayLaterModal(): void
  messagingDisplay: string
  isKlarnaEnabled: boolean
}

export const BuyNowPayLaterModal: FunctionComponent<IBuyNowPayLaterModalProps> = ({
  isBuyNowPayLaterModalOpen,
  handleCloseBuyNowPayLaterModal,
  messagingDisplay,
  isKlarnaEnabled,
}) => {
  const tv = useTranslation()
  return (
    <Modal
      data-testid="bnpl-modal"
      widthOverride="auto"
      isOpen={isBuyNowPayLaterModalOpen}
      onClose={handleCloseBuyNowPayLaterModal}
    >
      <ContentWrapper>
        <ModalBody>
          <ModalHeader>
            <h2>
              <Trans
                i18nKey={`${TP}.modalHeader`}
                t={tv.t}
                defaults="PAY OVER TIME <0>(US ONLY)</0>"
                components={[<Unbolded />]}
              />
            </h2>
            <StyledModalClose data-qa="bnpl-modal-close" onClick={handleCloseBuyNowPayLaterModal}>
              <SVGIcon name="closeX" />
            </StyledModalClose>
          </ModalHeader>
          <BnplSubHeader>
            <Trans
              i18nKey={`${TP}.BnplSubHeader`}
              t={tv.t}
              defaults="<0>Buy now and pay later with the payment option that works best for you.</0><0>To pay for your item in installments, simply select your preferred payment option during checkout.</0>"
              components={[<p />]}
            />
          </BnplSubHeader>
          {messagingDisplay !== 'pdp-affirm-message' && isKlarnaEnabled && (
            <PaymentTypeCard>
              <h2>
                <StyledKlarnaIcon />
              </h2>
              <p>
                <Trans
                  i18nKey={`${TP}.klarnaEnabled`}
                  t={tv.t}
                  defaults="Depending on the price of your item, you can pay in 4 interest-free payments every 2 weeks or pay over time with monthly financing. Monthly financing through Klarna issued by WebBank, member FDIC. *See payment <0>terms</0>. A higher initial payment may be required for some consumers. CA resident loans made or arranged pursuant to a California Financing Law license.​"
                  components={[
                    <a
                      target="blank"
                      href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/sliceitinx"
                    />,
                  ]}
                />
              </p>
            </PaymentTypeCard>
          )}
          <PaymentTypeCard>
            <h2>
              <AffirmIcon />
            </h2>
            <p>
              <Trans
                i18nKey={`${TP}.affirmPay`}
                t={tv.t}
                defaults="Depending on the price of your item, you can pay in 4 interest-free payments every 2 weeks or with monthly financing. Payment options through Affirm are subject to eligibility, and may not be available in all states, and are provided by these lending partners: <0>Affirm's lending partners</0>. CA residents: Loans by Affirm Loan Services, LLC are made or arranged pursuant to a California Finance Lenders Law license.​"
                components={[<a target="blank" href="http://affirm.com/lenders" />]}
              />
            </p>
          </PaymentTypeCard>
          {(messagingDisplay === 'pdp-pay-in-4-message' ||
            messagingDisplay === 'pdp-all-options-message') && (
            <PaymentTypeCard>
              <h2>
                <AfterPayIcon />
              </h2>
              <p>
                <Trans
                  i18nKey={`${TP}.afterPay`}
                  t={tv.t}
                  defaults="For purchases less than $1,000, you can pay in 4 interest-free installments every 2 weeks. You must be over 18 and meet additional eligibility criteria to qualify. Late fees may apply. Estimated payment amounts shown on product pages exclude taxes and shipping fees, which are added at checkout. Click <0>here</0> for complete terms. CA residents: Loans are made or arranged pursuant to a California Finance Lenders Law license."
                  components={[
                    <a
                      target="blank"
                      href="https://www.afterpay.com/en-US/installment-agreement"
                    />,
                  ]}
                />
              </p>
            </PaymentTypeCard>
          )}
        </ModalBody>
      </ContentWrapper>
    </Modal>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.FC2_WHITE};
  border: 1px solid ${colors.FC2_LIGHTEST_GREY};
`
const ModalBody = styled.div`
  width: 100%;
  background-color: ${colors.FC2_WHITE};
  max-height: 550px;
  overflow-y: scroll;
  line-height: 18px;
`

const ModalHeader = styled.div`
  background-color: ${colors.FC2_WHITE};
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
`

const Unbolded = styled.span`
  font-weight: normal;
`
const PaymentTypeCard = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  margin: 20px;
`

const BnplSubHeader = styled.div`
  margin: 20px;
`

const StyledModalClose = styled.button`
  position: relative;
  top: 17px;
`

const StyledKlarnaIcon = styled(KlarnaIcon)`
  height: 26px;
`
