import styled from 'styled-components'
import fonts from 'shared/lib/fonts'
import { ProductQA } from 'shared/dataAttributes'
import { colors, media } from 'shared/lib'

const ProductTitleLayout = styled.div`
  display: flex;
  flex-direction: column;
  ${media.large`
    padding: 0 0 20px;
  `}
`

// todo: set default h tag styles
const BrandName = styled.h2`
  ${fonts.SUBTITLE_3}
  color: ${colors.FC2_GREY};
  margin: 4px 0 20px;
`
const ProductTemplateName = styled.h1`
  font-weight: unset;
  margin: 0;
  ${fonts.HEADER_1}
`

export interface IProductTitleProps {
  brand: string
  name: string
  sku?: string
  description?: string
  releaseDate?: string
}

const ProductTitle = (props: IProductTitleProps) => {
  const { brand, name } = props
  return (
    <ProductTitleLayout>
      <BrandName data-qa={ProductQA.ProductBrandName}>{brand}</BrandName>
      <ProductTemplateName data-qa={ProductQA.ProductTitle}>{name}</ProductTemplateName>
    </ProductTitleLayout>
  )
}

export default ProductTitle
