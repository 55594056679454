import { t } from 'localization'

const TP = 'productTemplate.utils.getProductSizeCategory'

export const getProductSizeCategory = (category: string) => {
  switch (category.toLowerCase()) {
    case 'men':
      return t(`${TP}.men`, 'men')
    case 'women':
      return t(`${TP}.women`, 'women')
    case 'youth':
      return t(`${TP}.youth`, 'youth')
    case 'infant':
      return t(`${TP}.infant`, 'infant')
    default:
      return category
  }
}
