import { isNil } from 'lodash/fp'
import { gql } from '@apollo/client'
import { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { colors } from 'shared/lib'
import useCurrency from 'shared/hooks/useCurrency'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { Currency } from 'shared/enums'
import { FeatureFlag } from 'shared/enums/FeatureFlag'

import { BuyNowPayLaterModal } from './BuyNowPayLaterModal'
import Button from 'shared/components/Button'
import { t } from 'localization'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'

const TP = 'productTemplate.components.PaymentPromotional'

export const GET_PROMO = gql`
  query getPromo {
    selectedCurrency @client {
      isoCode
    }
  }
`

const PromotionalWrapper = styled.div`
  margin: 20px 0 10px 0;
  color: ${colors.FC2_BLACK};
`

interface IPaymentPromotionalProps {
  lowestPrice?: number
}

const PAY_IN_4_CENT_LIMIT = 35000 // $350.00 USD
const ALL_OPTIONS_GENERIC_CENT_LIMIT = 100000 // $1000.00 USD
const KLARNA_AFFIRM_CENT_LIMIT = 1000000 // $10000.00 USD

const PaymentPromotional: FunctionComponent<IPaymentPromotionalProps> = ({ lowestPrice }) => {
  const { loading, error } = useCurrency()
  const { currencyCode: selectedCurrency } = useShoppingRegionContext()
  const isNotUSD = !!(selectedCurrency !== Currency.USD)

  // Hardcoded for QA until Feature Flag story is pulled into a sprint
  const isKlarnaEnabled = useFeatureFlag(FeatureFlag.WEB_FC_ENABLE_KLARNA)
  const PAY_IN_4_MESSAGE = isKlarnaEnabled
    ? t(
        `${TP}.payIn4MessageWithKlarana`,
        'Pay in 4 interest-free payments with Klarna, Affirm or Afterpay. ',
      )
    : t(`${TP}.payIn4Message`, 'Pay in 4 interest-free payments with Affirm or Afterpay. ')
  const ALL_OPTIONS_MESSAGE = isKlarnaEnabled
    ? t(`${TP}.allOptionsMessageWithKlarana`, 'Pay over time with Klarna, Affirm or Afterpay. ')
    : t(`${TP}.allOptionsMessage`, 'Pay over time with Affirm or Afterpay. ')
  const KLARNA_AFFIRM_MESSAGE = isKlarnaEnabled
    ? t(`${TP}.klarnaAffirmMessageWithKlarna`, 'Pay over time with Klarna or Affirm. ')
    : t(`${TP}.klarnaAffirmMessage`, 'Pay over time with Affirm. ')
  const AFFIRM_MESSAGE = t(`${TP}.affirmMessage`, 'Pay over time with Affirm. ')

  const bnplTaglineCopy = (() => {
    if (lowestPrice <= PAY_IN_4_CENT_LIMIT)
      return { name: 'pdp-pay-in-4-message', text: PAY_IN_4_MESSAGE }
    if (lowestPrice <= ALL_OPTIONS_GENERIC_CENT_LIMIT)
      return { name: 'pdp-all-options-message', text: ALL_OPTIONS_MESSAGE }
    if (lowestPrice <= KLARNA_AFFIRM_CENT_LIMIT)
      return { name: 'pdp-klarna-affirm-message', text: KLARNA_AFFIRM_MESSAGE }
    return { name: 'pdp-affirm-message', text: AFFIRM_MESSAGE }
  })()

  const [isBuyNowPayLaterModalOpen, setIsBuyNowPayLaterModalOpen] = useState(false)
  const handleCloseBuyNowPayLaterModal = (event: MouseEvent) => {
    event.preventDefault()
    setIsBuyNowPayLaterModalOpen(false)
  }
  const handleLearnMoreClick = () => {
    setIsBuyNowPayLaterModalOpen(true)
  }

  if (isNil(lowestPrice) || isNotUSD || loading || error) {
    return null
  }

  return (
    <>
      <PromotionalWrapper data-testid={bnplTaglineCopy.name}>
        {bnplTaglineCopy.text}
        <Button
          data-qa="pdp-bnpl-learn-more-button"
          buttonType="link"
          onClick={handleLearnMoreClick}
        >
          {t(`${TP}.learnMore`, 'Learn more')}
        </Button>
      </PromotionalWrapper>
      <BuyNowPayLaterModal
        isBuyNowPayLaterModalOpen={isBuyNowPayLaterModalOpen}
        handleCloseBuyNowPayLaterModal={handleCloseBuyNowPayLaterModal}
        messagingDisplay={bnplTaglineCopy.name}
        isKlarnaEnabled={isKlarnaEnabled}
      />
    </>
  )
}

export default PaymentPromotional
