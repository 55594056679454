import styled from 'styled-components'
import colors from 'shared/lib/colors'
import fonts from 'shared/lib/fonts'
import { FC } from 'react'
import { t } from 'localization'

const TP = 'productTemplate.components.SizeTable'

const Title = styled.div`
  text-align: center;
  ${fonts.SUBTITLE_2};
  padding-bottom: 20px;
`

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  width: 100%;
`

const Row = styled.tr`
  border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
  line-height: 42px;
`

const Cell = styled.td`
  color: ${colors.FC2_BLACK};
  padding: 5px 0;
  text-align: center;
  width: auto;
  min-width: 10px;
`

export interface SizeTableProps {
  title: string
  sizes: number[][]
  className?: string
}

export const SizeTable: FC<SizeTableProps> = props => {
  const { title, sizes, className } = props
  return (
    <div className={className}>
      <Title>{title}</Title>
      <Table>
        <thead>
          <tr>
            <th>{t(`${TP}.us`, 'US')}</th>
            <th>{t(`${TP}.uk`, 'UK')}</th>
            <th>{t(`${TP}.eu`, 'EU')}</th>
            <th>{t(`${TP}.cm`, 'CM')}</th>
          </tr>
        </thead>
        <tbody>
          {sizes.map((sizeGroup, i) => {
            const [US, UK, EU, CM] = sizeGroup
            return (
              <Row key={i}>
                <Cell>{US}</Cell>
                <Cell>{UK}</Cell>
                <Cell>{EU}</Cell>
                <Cell>{CM}</Cell>
              </Row>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}
export default SizeTable
