import { t } from 'localization'

const TP = 'shared.utils.box-condition-utils'

export const getBoxConditionText = (condition: string) => {
  switch (condition.toLowerCase()) {
    case 'missing lid':
      return t(`${TP}.missingLid`, 'Missing Lid')
    case 'badly damaged box':
      return t(`${TP}.badlyDamagedBox`, 'Badly Damaged Box')
    case 'no original box':
      return t(`${TP}.noOriginalBox`, 'No Original Box')
    case 'good condition':
      return t(`${TP}.goodCondition`, 'Good Condition')
    default:
      return condition
  }
}
