import { startCase } from 'lodash/fp'
import { FC } from 'react'
import { FCLink } from 'shared/components/Links'
import { fonts } from 'shared/lib'
import styled from 'styled-components'

interface IBreadcrumbProps {
  dataQa?: string
  dataTestId?: string
  href: string
  emphasis?: boolean
  fontColor?: string | null | undefined
  name: string
  hasTrailingSlash?: boolean
  lightenOnHover?: boolean
}

const CrumbWrapper = styled.li`
  display: inline-block;
`
const CrumbLink = styled(FCLink)<{ fontColor: string }>`
  ${fonts.LINK}
  ${({ fontColor }) => (fontColor ? `color: ${fontColor}` : 'color: inherit')}
`
const CrumbSeparator = styled.span<{ fontColor?: string }>`
  ${fonts.LINK}
  text-decoration: none;
  ${({ fontColor }) => (fontColor ? `color: ${fontColor}` : 'color: inherit')};
  padding: 0px 5px;
`

const BreadcrumbLink: FC<IBreadcrumbProps> = ({
  dataQa,
  href,
  emphasis = false,
  fontColor,
  name,
  hasTrailingSlash = false,
  lightenOnHover = false,
  dataTestId = 'breadcrumbLink',
}) => {
  return (
    <CrumbWrapper data-qa={dataQa} data-testid={dataTestId}>
      <CrumbLink
        href={href}
        emphasis={emphasis}
        fontColor={fontColor}
        lightenOnHover={lightenOnHover}
      >
        {startCase(name)}
      </CrumbLink>
      {hasTrailingSlash && <CrumbSeparator fontColor={fontColor}>/</CrumbSeparator>}
    </CrumbWrapper>
  )
}

export default BreadcrumbLink
