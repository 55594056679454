import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { AlgoliaIndex } from 'search'
import AlgoliaClient from 'search/components/AlgoliaSearchClient'
import ProductHits from 'search/components/ProductHits'

type TrendingProductsProps = {
  hidePrices: boolean
}

export const TrendingProducts = ({ hidePrices }: TrendingProductsProps) => (
  <InstantSearch indexName={AlgoliaIndex.Trending} searchClient={AlgoliaClient}>
    <Configure hitsPerPage={8} distinct />
    <ProductHits hidePrices={hidePrices} />
  </InstantSearch>
)

export default TrendingProducts
