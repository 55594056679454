import Carousel from 'shared/components/Carousel'
import { media } from 'shared/lib'
import styled from 'styled-components'

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  min-width: 350px;
  min-height: 365px;
  padding: 0 48px 0 0;
  ${media.large`
    max-width: 570px;
  `}
  ${media.extraLarge`
    max-width: 680px;
  `}
`

export interface IDesktopProductPictureCarouselProps {
  className?: string
  pictures: string[]
}

const DesktopProductPictureCarousel = (props: IDesktopProductPictureCarouselProps) => {
  const { className, pictures } = props

  return (
    <CarouselContainer className={className}>
      <Carousel pictureUrls={pictures} />
    </CarouselContainer>
  )
}

export default DesktopProductPictureCarousel
