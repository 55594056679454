import { Request } from 'express'
import { getLocalePath, t } from 'localization'
import { flow, head, map, replace, split, toLower } from 'lodash/fp'
import { startCase } from 'shared/utils/formatting-utils'

const TP = 'head.utils.seoHelper'

/**
 * [getAbsoluteUrl]
 * @typedef {Object} AbsoluteUrlReturn
 * @property {string} protocol - e.g, http or https
 * @property {string} host - e.g., flightclub.com
 * @property {string} origin - protocol//host (e.g, https://flightclub.com)
 *
 * @param  {Object} req - request object
 * @returns {AbsoluteUrlReturn} - protocol, host, and origin
 */
export function getAbsoluteUrl(req: Request) {
  const xForwardedHost = 'x-forwarded-host'
  const hostFromHeaders = 'host'

  let host

  if (typeof req !== 'undefined') {
    host = req.headers[xForwardedHost] || req.headers[hostFromHeaders]
  } else if (typeof window === 'undefined' || !window.location || !window.location.host) {
    host = ''
  } else {
    host = window.location.host
  }

  const protocol = host.indexOf('localhost') > -1 ? 'http:' : 'https:'

  return {
    protocol,
    host,
    origin: `${protocol}//${host}`,
  }
}

const removeTrailingSlash = replace(/\/$/, '')

/**
 * [sanitizeUrl]
 * @param  {string} location
 * @return {string}
 * Returns the sanitized Url which has had its query params and
 * trailing slash removed and has been lowercased
 */
export function sanitizeUrl(location: string) {
  return flow(split(/[?#]/), head, removeTrailingSlash, toLower)(location)
}

export function buildTitle(titleArray: string[] = []) {
  if (!titleArray.length) {
    return t(`${TP}.flightClub`, 'Flight Club')
  }
  return titleArray.filter(Boolean).join(' - ')
}

/**
 * Return the canonical URL for the current page.
 */
export const getCanonicalUrl = (
  req: Request,
  currentPath: string,
  originalUrl: string,
  locale: string,
  defaultLocale: string,
  locales: string[],
) => {
  const { origin } = getAbsoluteUrl(req)
  const originLocation = `${origin}${originalUrl}`
  const sanitizedUrl = sanitizeUrl(originLocation)
  const localedRoute = locales?.some((loc) => loc === locale && loc !== defaultLocale)

  return currentPath === '/' && !localedRoute ? `${sanitizedUrl}/` : sanitizedUrl
}

interface IBreadCrumb {
  description: string
  name: string
  slug: string
}
// category comes through as the last breadcrumb
// If we don't need brand we return a title without it
const getCategoryNameFromBreadcrumb = (fullPath: IBreadCrumb[]) =>
  fullPath
    .slice(-1)[0]
    .name.replace('/', ' ')
    .replace('Dunks', 'Dunk')
    .replace('Jordan Future', 'Future')
    .replace('More Sneakers', t(`${TP}.shopAll`, 'Shop All'))
    .replace('Posite', 'Foamposite')
    .replace('Pre-Owned', t(`${TP}.used`, 'Used'))
    .replace(/Jordans$/, 'Jordan')
    .replace(/^Dunk SB$/, 'SB')
const getBrandNameFromBreadcrumb = (fullPath: IBreadCrumb[]) => {
  const brandIndex = /^navigation$/i.test(fullPath[0].slug) ? 1 : 0
  return (
    fullPath[brandIndex].name
      .replace('Yeezy', 'Adidas')
      .replace('Air Jordans', 'Air Jordan')
      .replace('Pre-Owned', t(`${TP}.used`, 'Used')) || ''
  )
}
export function buildPLPTitle(fullPath: IBreadCrumb[]) {
  // Men -> Men's && Women -> Women's
  const category = getCategoryNameFromBreadcrumb(fullPath).replace(/men/i, "$&'s")
  if (/Other Jordan/.test(category)) {
    return t(`${TP}.otherAirJordanSneakers`, 'Other Air Jordan Sneakers')
  }
  if (/Other/.test(category)) {
    return t(`${TP}.category`, {
      defaultValue: '{category} Sneakers',
      category,
    })
  }
  // if category EXACTLY EQUALS "New Releases"
  if (/^New Releases$/.test(category)) {
    return t(`${TP}.newReleases`, 'New Sneaker Releases')
  }
  // if category ENDS WITH "New Releases"
  if (/New Releases$/.test(category)) {
    return category
  }
  if (/apparel/i.test(category)) {
    return category
  }
  const brand = getBrandNameFromBreadcrumb(fullPath)
  if (
    // (nike|adidas|jordan) typically appear in front of the category
    /(nike|adidas|jordan)/i.test(brand) &&
    // but not if the category already starts with them
    category.indexOf(brand.slice(0, -1)) < 0 &&
    // or if the category is Nike Yeezy
    /Nike Yeezy/.test(category) === false
  ) {
    return t(`${TP}.brandCategorySneakers`, {
      defaultValue: '{brand} {category} Sneakers',
      brand,
      category,
    })
  }
  return t(`${TP}.category`, {
    defaultValue: '{category} Sneakers',
    category,
  })
}

export const buildPLPHeadline = (fullPath: IBreadCrumb[]) => {
  const brand = getBrandNameFromBreadcrumb(fullPath)
  const category = getCategoryNameFromBreadcrumb(fullPath)
  if (/Other Jordan/.test(category)) {
    return t(`${TP}.otherAirJordan`, 'Other Air Jordan')
  }
  if (/\w* Air Jordan$/.test(category) && /Top Air Jordan/.test(category) === false) {
    return `${category}s`
  }
  if (
    category.indexOf(brand.slice(0, -1)) < 0 &&
    /More Sneakers/.test(brand) === false &&
    /(Nike Yeezy|Other)/.test(category) === false
  ) {
    return `${brand} ${category}`
  }
  if (/More Sneakers/.test(brand) && /Shop All/.test(category)) {
    return t(`${TP}.shopAllSneakers`, 'Shop All Sneakers')
  }
  return category
}

interface IPDPMetaDescriptionProps {
  brand?: string
  category?: string
  name: string
}
export const buildPDPMetaDescription = ({
  brand,
  category = 'shoes',
  name,
}: IPDPMetaDescriptionProps) =>
  t(`${TP}.pdpMetaDescription`, {
    defaultValue:
      'Shop the {name} and discover the latest {category}{brand} from {brandStartCase} and more at Flight Club, the most trusted name in authentic sneakers since 2005. International shipping available.',
    name: startCase(name),
    category,
    brand,
    brandStartCase: startCase(brand || ''),
  })

export const buildPLPMetaDescription = (fullPath: IBreadCrumb[], firstProductName?: string) => {
  const title = buildPLPTitle(fullPath)
  if (firstProductName) {
    return t(`${TP}.plpMetaDescriptionWithFirstProductName`, {
      defaultValue:
        'Shop the latest {title}, including the {firstProductNameStartCase} and more at Flight Club, the most trusted name in authentic sneakers since 2005. International shipping available.',
      title,
      firstProductName,
      firstProductNameStartCase: startCase(firstProductName),
    })
  }
  return t(`${TP}.plpMetaDescription`, {
    defaultValue:
      'Shop the latest {title} and more at Flight Club, the most trusted name in authentic sneakers since 2005. International shipping available.',
    title,
  })
}

export const createListItem = (id: string, name: string, position?: number) => ({
  '@type': 'ListItem',
  position,
  item: {
    '@id': id,
    name,
  },
})

export const addBreadCrumbPositions = (crumbs) => {
  let position = 1

  return map((crumb) => ({ ...crumb, position: position++ }))(crumbs)
}

export const mapToListItems = (hostname: string, locale?: string) =>
  map(({ name, slug }) => {
    const crumbRoute = `${hostname}${getLocalePath(`/${slug}`, locale)}`
    return createListItem(crumbRoute, name)
  })

// The canonicalSlugMap is used on both FilteredGridPage and CollectionPage
// FilteredGridPage slugs have a preceeding slash
// CollectionPage slugs do not have a preceeding slash
export const canonicalSlugMap = new Map([
  ['/adidas/other-adidas', '/adidas'],
  ['/collections/supreme', '/supreme'],
  ['/nike/nike-cross-training/other-cross-training', '/nike/nike-cross-training'],
  ['/nike/nike-off-white', '/collections/nike-off-white'],
  ['/nike/nike-running/other-running', '/nike/nike-running'],
  ['/sneakers/other-brands', '/sneakers'],
  ['yeezy', '/adidas/adidas-yeezy'],
  ['supreme', '/supreme'],
])

export const breadcrumbSpecialNameMap = new Map([['Yeezy', 'adidas Yeezy']])
