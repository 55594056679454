import { t } from 'localization'
import { FC, ReactNode } from 'react'
import { BlurredBackDrop } from 'shared/components/BlurredBackDrop'
import { SVGIcon } from 'shared/components/Icons'
import { ProductQA } from 'shared/dataAttributes'
import { colors, zIndex } from 'shared/lib'
import styled from 'styled-components'

const TP = 'shared.components.MobileOverlay'

interface IMobileOverlayProps {
  isOpen: boolean
  title: ReactNode
  onClose(): void
}

interface IMobileOverlayStyleProps {
  isOpen: boolean
}

const Header = styled.div`
  border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Close = styled.button`
  margin-left: 15px;
  font-size: 20px;
`
// Transform moves size picker out of the way if there are up to 7 rows of sizes
// We should look into a better solution
const Content = styled.div`
  z-index: ${zIndex.pageTakeover};
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  background-color: ${colors.FC2_WHITE};
  border-top: 1px solid ${colors.FC2_LIGHTEST_GREY};
  transition-property: transform;
  transition-duration: 0.5s;
  ${({ isOpen }: IMobileOverlayStyleProps) =>
    isOpen ? `transform: translate(0px, 0px);` : `transform: translate(0px, 650px);`}
`
export const MobileOverlay: FC<IMobileOverlayProps> = (props) => {
  const { isOpen, title, onClose, children } = props

  // Use data attribute to ensure correct target for click to dismiss
  const shouldElementDismissDialog = (e: React.MouseEvent<HTMLElement>) => {
    try {
      return (e.currentTarget as HTMLElement).getAttribute('data-dismiss-on-click')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return false
    }
  }

  const handleDismiss = () => {
    onClose()
  }

  const handleClickToDismiss = (e: React.MouseEvent<HTMLElement>) => {
    if (shouldElementDismissDialog(e)) {
      handleDismiss()
    }
  }

  return (
    <>
      <BlurredBackDrop
        style={{ top: '0px', left: '0px' }}
        data-dismiss-on-click
        isActive={isOpen}
        onClick={handleClickToDismiss}
      />
      <Content isOpen={isOpen}>
        <Header>
          {title}
          <Close
            data-qa={ProductQA.MobileBuyNewCloseButton}
            data-dismiss-on-click
            onClick={handleClickToDismiss}
            aria-label={t(`${TP}.close`, 'Close overlay icon')}
          >
            <SVGIcon name="closeX" />
          </Close>
        </Header>
        {children}
      </Content>
    </>
  )
}

export default MobileOverlay
