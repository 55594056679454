import Button from 'shared/components/Button'
import { ProductQA } from 'shared/dataAttributes'
import { t } from 'localization'

const TP = 'productTemplate.components.ProductPicker.OutOfStockNotice'

const OutOfStockNotice = () => (
  <Button fill disabled qaAttr={`${ProductQA.OutOfStock}`} data-testid="buy-button-out-of-stock">
    {t(`${TP}.outOfStock`, 'Out of Stock')}
  </Button>
)

export default OutOfStockNotice
